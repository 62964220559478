app-user-history-order-movie-poster-component {

  .movie-poster {
    width: 190px;
    height: 290px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;

    .movie {

      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-items: center;
      justify-content: center;

      .movie-title {

        font-weight: normal;
        color: white;
        padding: 0rem 1rem 1rem 1rem;
        font-size: 1.3vw;
      }

    }

  }

  .label-release {
    background: $dark-blue;
    color: #FFFFFF;
    width: 70%;

    padding: 7px 7px;
    text-align: left;
    border-radius: 0px 0px 18px 0px;

    &.male {
      background-color: #004B87;
    }
  }



}

*[dir="rtl"]  app-user-history-order-movie-poster-component {
  display: block;
  clear: both;

  .label-release {
    text-align: right;
    border-radius: 0px 0px 0px 18px;
  }



}
