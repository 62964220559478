app-catering-article-popup {

  .popup-area {

    .footer {

      .cancel-button-area {

        justify-content: center !important;

      }

      .add-button-area {

        justify-content: center !important;

      }

    }

  }

}
