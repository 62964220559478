@import "icon";

app-screen-seats {

  .screen-container {

    display: flex;
    flex-direction: column;
    width: 100%;

    @include size-list;

    .screen-row {
      display: flex;
      flex-direction: row;
      justify-content: center;

      .screen {
        background: #e3e3e3;
        text-align: center;
        width: 100%;
        padding: 2px;
        font-size: 110%;
        margin-bottom: 20px;
        border: 1px solid #afafaf;
      }

      .screen-placeholder {
        width: $seats-column-size+px;
        height: $seats-row-size+px;
        margin: $seats-column-margin+px;
        text-align: center;
        @include size-placeholder-list;
        align-self: center;
        &.row-legend {
          color: #EFEFEF;
          width: ($seats-column-legend-size)+px;
          line-height: $seats-row-size+px;
        }
        &.lounge {
          border: 1px solid #afafaf;
          height: 40px;
          margin: 0px;
          padding-top: 7px;
          position: relative;
          margin-top:4px;
          &:before {
            background: #292223;
            content: "";
            position: absolute;
            width: 20px;
            height: 4px;
            bottom:-2px;
            left: 50%;
            transform: translate(-50%, 0);

          }
        }

      }

    }

  }
}

//
//height: 3px;
//background: white;
//position: absolute;
