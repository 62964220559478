app-screen-seats {
  display: block;
  overflow-y: hidden;
  overflow-x: auto;
  padding-bottom: 30px;

  scrollbar-color: #800020 transparent;
  scrollbar-width: thin;
  min-height: 300px;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $dark-gray;
    border-radius: 30px;
  }

  .footer-template-container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: right;

    .back-row {
      color: $yellow;
      font-weight: bold;
      text-align: center;
      border-radius: 0;
      width: 100%;
      margin-left: unset !important;
      margin-right: unset !important;
      text-transform: uppercase;
    }
  }

  .screen-seats-container.zoomed {
    padding-top: 0 !important;
    position: relative;
    bottom: 25px;
  }

  .screen-seats-container {
    display: grid;
    flex-direction: column;
    width: 100%;

    @include size-list;
    .head {
      background: url("/assets/images/Screen.svg");
      background-position: center bottom;
      background-repeat: no-repeat;
      background-size: contain;
      height: 9rem;
      margin: 1rem 0;
      position: relative;

      span {
        position: absolute;
        display: block;
        bottom: 0;
        text-align: center;
        width: 100%;
        letter-spacing: 2rem;
        text-transform: uppercase;
      }
    }

    .screen-row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      position: relative;

      .screen {
        background: transparent;
        border: none;
        background-repeat: no-repeat;
        background-position: left center;
        background-size: contain;
        height: 0;
        width: 100%;
        margin-left: #{$seats-column-legend-size}px;
        padding-bottom: calc(100% * 1 / 6);
      }

      .screen-placeholder {
        display: block;
        width: #{$seats-column-size}px;
        height: #{$seats-row-size}px;
        margin: #{$seats-column-margin}px;
        text-align: center;
        @include size-placeholder-list;
        align-self: center;
        z-index: 1;

        &.row-legend {
          color: $gray;
          width: #{$seats-column-legend-size}px;
          min-width: #{$seats-column-legend-size}px;
          margin: auto;
          padding: 10px 0;

          .row-symbol {
            font-family: $font-family-base;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 119%;
          }
        }

        &.legend {
          display: inline-block;
        }

        &.lounge {
          border: 1px solid #afafaf;
          position: relative;
          margin-top: 0;

          &:before {
            background: #292223;
            content: "";
            position: absolute;
            width: 20px;
            height: 4px;
            bottom: -2px;
            left: 50%;
            transform: translate(-50%, 0);
          }
        }
      }

      .screen-placeholder-left {
        text-align: left;
      }

      .screen-placeholder-right {
        text-align: right;
      }

      .line {
        position: absolute;
        top: 45%;
        width: calc(100% - #{$seats-column-size * 2}px);
        height: 1px;
        border-bottom: 1px solid #d8d8d8;
        margin: 0 auto;
        z-index: 0;
      }

      bs-tooltip-container {
        .tooltip {
          z-index: inherit;
        }
      }
    }

    @for $i from 1 through 10 {
      &.size-#{$i} {
        .screen {
          zoom: calc(($i - 1) / 10);
        }
      }
    }
  }

  .strike {
    display: block;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    padding: 30px;
    > span {
      position: relative;
      display: inline-block;
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        width: 9999px;
        height: 1px;
        background: $light-blue;
      }
      &:before {
        right: 100%;
        margin-right: 15px;
      }
      &:after {
        left: 100%;
        margin-left: 15px;
      }
    }
  }

  .wall {
    background-size: contain;
    background-position: center center;
    &.vertical {
      background-image: url("/assets/images/elements/vertical-wall.svg") !important;
    }
    &.horizontal {
      background-image: url("/assets/images/elements/horizontal-wall.svg") !important;
    }
  }

  [class*="emergency"] {
    background-size: contain;
    background-position: center center;
    &.emergency_down {
      background-image: url("/assets/images/elements/emergency_right.svg") !important;
    }
    &.emergency_left {
      background-image: url("/assets/images/elements/emergency_right.svg") !important;
    }
    &.emergency_right {
      background-image: url("/assets/images/elements/emergency_right.svg") !important;
    }
    &.emergency_up {
      background-image: url("/assets/images/elements/emergency_right.svg") !important;
    }
  }

  [class*="exit"] {
    background-size: contain;
    background-position: center center;
    background-image: url("/assets/images/elements/exit.svg") !important;
    &.exit_down {
      background-image: url("/assets/images/elements/exit_down.svg") !important;
    }
    &.exit_left {
      background-image: url("/assets/images/elements/exit_left.svg") !important;
    }
    &.exit_right {
      background-image: url("/assets/images/elements/exit_right.svg") !important;
    }
    &.exit_up {
      background-image: url("/assets/images/elements/exit_up.svg") !important;
    }
  }

  [class*="rails"] {
    background-size: contain;
    background-position: center center;
    &.rails_down {
      background-image: url("/assets/images/elements/rails_down.svg") !important;
    }
    &.rails_left {
      background-image: url("/assets/images/elements/rails_left.svg") !important;
    }
    &.rails_right {
      background-image: url("/assets/images/elements/rails_right.svg") !important;
    }
    &.rails_up {
      background-image: url("/assets/images/elements/rails_up.svg") !important;
    }
  }

  [class*="stairs"] {
    background-size: contain;
    &.stairs_down {
      background-image: url("/assets/images/elements/stairs_down.svg") !important;
    }
    &.stairs_up {
      background-image: url("/assets/images/elements/stairs_up.svg") !important;
    }
  }

  [class*="entrance"] {
    background-size: contain;
    background-position: center center;
    &.entrance_left {
      background-image: url("/assets/images/elements/entrance_left.svg") !important;
    }
    &.entrance_right {
      background-image: url("/assets/images/elements/entrance_right.svg") !important;
    }
  }

  .seat {
    position: relative;
    button {
      + .tooltiptext {
        font-family: $font-family-base;
        visibility: hidden;
        min-width: 140px;
        color: #fff;
        text-align: center;
        background-color: #242424dd;
        border-radius: 2px;
        padding: 12px 6px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);

        /* Position the tooltip */
        position: absolute;
        z-index: 1;
        bottom: 100%;
        left: 50%;
        margin-left: -70px;
        margin-bottom: 5px;
        white-space: nowrap;

        &:after {
          content: " ";
          position: absolute;
          top: 100%; /* At the bottom of the tooltip */
          left: 50%;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: #242424dd transparent transparent transparent;
        }
      }

      &:hover + .tooltiptext {
        visibility: visible;
      }
    }
  }
}

@media (max-width: 576px) {
  app-screen-seats {
    zoom: 0.8;
  }
}
