app-page-personal {
  .btn {
    text-transform: uppercase;
    min-width: 140px;
  }

  .error-message-box {
    color: $white;
    background-color: $error-color;
    text-align: center;
    h6 {
      margin: 0;
    }
  }

  input::placeholder {
    opacity: 0.5 !important;
  }
}
