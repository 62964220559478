app-screen-movie {

  .screen-movie-container {
    .movie-title {
      padding-bottom: 0rem;
    }
  .more {
    display: block;
    color:#004B87;
    cursor: pointer;
    padding-top: 10px;
  }
  }
}
