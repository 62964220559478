app-screen-seats-multiseat {
  width: 100%;
  display: block;
  div.seat {

    button {
      cursor: pointer;
      display: block;
      width: 100%;
      height: 100%;
      border: none;
      background-color: transparent;
      margin: 0;
      padding: 0;

      &:focus, &.active {
        outline: none;
      }
    }

    &.lounge {
      width: #{$seats-column-size - 1}px;
      height: $seats-row-size+px;
      display: inline-block;
      margin-right: 0px;
      &:first-child {
          border-radius: 50% 0 0 50%;
      }

      &:last-of-type {
        margin-right: 0px;
        border-radius:0 50% 50% 0
      }
      &.free {
        @extend .icon, .free;
      }

      &.mine {
        background-color: $background-icon-mine !important;
        content: fa-content($fa-var-user);
      }

      &.occupied {
        @extend .icon, .occupied;

        button {
          cursor: default;
        }
      }
    }

    &.couch {

      width: #{$seats-column-size - 1}px;
      height: $seats-row-size+px;
      display: inline-block;
      border-radius: 50%;
      margin-right: -2px;
      &:first-child {

        //  border-radius: 50% 0 0 50%;
      }
      &:last-child {
        margin-right: 0px;
        //border-radius:0 50% 50% 0
      }
      &.free {
        @extend .icon, .free;
      }

      &.mine {
        background-color: $background-icon-mine !important;
        content: fa-content($fa-var-user);
      }

      &.occupied {
        @extend .icon, .occupied;

        button {
          cursor: default;
        }
      }
    }
  }
}
