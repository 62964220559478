*[app-screening-list-item],
app-screening-list-item {
  .card {
    border: none;
    border-radius: 0;
    min-height: 513px;
    margin: 10px auto;

    .btn {
      color: $white;
      background: $light-blue;
      text-align: center;
      padding: 4px;
      border: 1px solid $light-blue;
      text-decoration: none;
      font-weight: bold;
      font-size: 12px;
      border-radius: 0;
      display: inline-block;
      min-width: 62px;
      transition: all 0.3s;
      margin: 0 5px 5px 0;

      &:hover {
        background: $white;
        border: 1px solid $light-blue;
        color: $light-blue !important;
        cursor: pointer;
      }
      &:disabled {
        color: $white !important;
        background: $light-blue !important;
        border: 1px solid $light-blue !important;
        cursor: default !important;
      }
    }

    .movie-head {
      .movie-title {
        font-size: 16px;
        color: $white;
        text-transform: uppercase;
        overflow: hidden;
        text-align: left;
        display: block;
        margin: 10px 0 0px 0;
        line-height: 1.2;
      }

      .movie-description {
        color: $white;
        line-height: 1.3;
        min-height: 5rem;
        max-height: 6rem;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
      }
    }

    .purchase-info {
      color: $light-blue;
      text-decoration: uppercase;
      text-align: center;
      margin: 18px 0;
      font-family: $font-family-base;
    }

    .cinema-info {
      text-transform: uppercase;
      text-align: center;
    }

    .movie-screenings {
      display: grid;
      gap: 0.5rem;
      grid-template-columns: 1fr 1fr;

      @media (max-width: 992px) {
        grid-template-columns: 1fr 1fr 1fr;
      }

      @media (min-width: 993px) {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}

.movie-attributes {
  font-size: 1.5rem;
  gap: 0.75rem;

  &:not(:first-child) {
    margin-top: 1rem;
  }
}

.movie-attribute {
  line-height: initial;
  &-default-fs {
    line-height: initial;
    font-size: 1.3rem;
  }
}

.oc-attribute {
  background: url("/assets/images/open_caption.svg");
  background-repeat: no-repeat;
  min-height: 19px;
  width: 22px;
  margin-top: 4px;
}
