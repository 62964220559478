app-user-status {
  position: relative;

  > div {
    justify-content: end;

    @media (max-width: 767px) {
      justify-content: center;
    }
  }

  .buttons {
    display: flex;
    gap: 0.5rem;

    @media (max-width: 767px) {
      flex-direction: column;
      gap: 0rem;
    }
  }

  .status {
    &-bar {
      color: white;
      height: 100%;

      > span {
        color: $light-blue;
      }
      i {
        color: $red;
      }
    }
  }

  .btn-link {
    white-space: nowrap;
  }
}
