.btn-primary {
  border: 1px solid gray !important;
}

.btn {
  border-radius: 5px;
  position: relative;
  font-weight: 500;
  font-size: 1rem;

  &:last-child {
    margin-right: 0;
  }
  &:first-child {
    margin-left: 0;
  }

  &-pay {
    &-google-pay {
      border: solid 2px $google-pay-button-border;
      background-color: $google-pay-button-background;
      color: $google-pay-button-color !important;
      box-shadow: none;

      &:hover,
      &:active {
        background-color: $google-pay-button-background-hover !important;
        border-color: $google-pay-button-border-hover !important;
      }
      &-outline {
        border: solid 2px $google-pay-button-border;
        background-color: $btn-transparent;
        color: $google-pay-button-color !important;
        &:hover,
        &:active {
          border-color: $google-pay-button-border-hover !important;
          color: $google-pay-button-color !important;
        }
      }

      @media (max-width: 576px) {
        font-size: 1rem;
      }
    }
    &-apple-wallet {
      border: solid 2px $apple-pay-button-border;
      background-color: $apple-pay-button-background;
      color: $apple-pay-button-color !important;
      box-shadow: none;

      &:hover,
      &:active {
        background-color: $apple-pay-button-background-hover !important;
        border-color: $apple-pay-button-border-hover !important;
      }
      &-outline {
        border: solid 2px $apple-pay-button-border;
        background-color: $btn-transparent;
        color: $apple-pay-button-color !important;
        &:hover,
        &:active {
          border-color: $apple-pay-button-border-hover !important;
          color: $apple-pay-button-color !important;
        }
      }

      @media (max-width: 576px) {
        font-size: 1rem;
      }
    }

    img {
      height: 27px !important;
    }
  }
}

@media (min-width: 360px) {
  .btn .btn-scr {
    margin-right: 2px;
    margin-left: 2px;
    margin-bottom: 4px;
  }
}

@media (min-width: 1024px) {
  .btn .btn-scr {
    margin-left: 5px;
    margin-bottom: 5px;
  }
}

.btn-borderless-link {
  height: auto !important;
  border: none;
  background-color: transparent;
}

.btn-link {
  color: white;
  text-decoration: none !important;

  &:hover {
    color: #bf0b38;
    text-decoration: none;
  }
}

.btn-aslink {
  color: $light-blue !important;
  text-decoration: none;
  font-size: inherit;

  &:hover {
    text-decoration: underline;
  }

  &:disabled {
    background: none !important;
  }
}

.btn-border {
  display: flex;
  gap: 0.3rem;
  align-items: center;
  border: 1px solid $white;
  border-radius: 0;
}

button.non-clickable {
  pointer-events: none;
}
