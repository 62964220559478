app-screen-movie {
  display: block;
  clear: both;

  .screen-movie-container {
    color: black;

    .poster {
      height: 122px;
      width: 82px;

      .img-fluid {
        max-height: 100%;
        max-width: 100%;
        height: 122px;
        width: auto;
      }
    }

    .movie-container {
      font-family: $font-family-base;
      font-style: normal;
      font-size: 1rem;
      font-weight: normal;
      line-height: 1.125rem;

      &-top {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 2px;

        color: #C55E7A;
      }
      &-title {
        font-size: 1.75rem;
        line-height: 2rem;
        font-weight: bold;
      }
    }

    
  }

  .screen-feature {
    word-wrap: break-word;
  }
}