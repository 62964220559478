app-basket-list {

  .list-tickets {


    .seat-column {

      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.7rem;

    }

    select {
      font-size: .6rem;
      font-weight: bold;
    }
    .select {
      font-size: .6rem;
    }


    .select::after {
      font-size: 0.5rem;

    }

  }

}
