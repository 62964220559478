app-page-message {
  .card-header {
    padding: 1.75rem 1.25rem;
    h3 {
      color: #ffffff;
    }
  }

  .card-body {
    padding: 1.75rem 1.25rem;
  }

  .card-footer {
    text-align: center;
    padding: 1.75rem 1.25rem;
    background: #dff7fb;
  }
}
