app-user-register {
  .register-button {
    color: #004b87;
    text-decoration: underline;
    font-weight: bold;
  }

  .card-footer {
    padding: 0 30px;
  }

  .card-body {
    padding: 0px 30px;
  }

  .card-header {
    padding: 20px 30px;
  }

  h3 {
    color: #4a4a4a;
    font-weight: bold;
    font-size: 16px;
  }
}
