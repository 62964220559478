app-screen-seats-selected-list {
  background-color: $dark;
  .seats-selected-list-container {
    span {
      display: inline-block;
    }
    span.seat:after {
      content: ',\00a0';
    }
    span.seat:last-child:after {
      content: '';
    }
  }
}
