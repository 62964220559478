app-screen-legend {
  .items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;

    @media (min-width: 768px) and (max-width: 1200px) {
      grid-template-columns: 1fr;
    }

    .item {
      display: inline-flex;

      .label {
        font-size: 0.8rem;
        font-weight: normal;
        margin: auto 1rem;
      }

      .ico {
        padding: 0 1rem;
        margin: 0;
        height: #{$seats-row-size * 0.8}px;
        line-height: #{$seats-row-size * 0.8}px;
        min-width: #{$seats-column-size * 0.8}px;
        vertical-align: middle;
        text-align: center;
        background-repeat: no-repeat;
        font-size: 0.8rem;
        font-weight: bold;
        padding-right: 0 !important;
        padding-left: 0 !important;

        &:before {
          background-repeat: no-repeat;
          background-size: contain;
          width: #{$seats-column-size * 0.8}px;
          height: #{$seats-row-size * 0.8}px;
          line-height: #{$seats-row-size * 0.8}px;
          content: "";
          margin-left: 0.3rem;
          margin-right: 0 !important;
          display: block;
        }

        &.free:before {
          background-color: $light-blue !important;
          background-repeat: no-repeat;
          background-size: contain;
          width: #{$seats-column-size * 0.8}px;
        }

        &.occupied:before {
          background-color: $light-gray !important;
          background-repeat: no-repeat;
          background-size: contain;
          width: #{$seats-column-size * 0.8}px;
          content: "";
        }

        &.mine:before {
          background-color: $red !important;
          background-repeat: no-repeat;
          background-size: contain;
          width: #{$seats-column-size * 0.8}px;
          content: "";
        }

        &.couch:before {
          background-image: url("/assets/images/seats/twin.png");
          background-repeat: no-repeat;
          background-size: contain;
          width: #{$seats-column-size * 2 * 0.8}px;
          border-radius: 0;
          content: "";
        }

        &.wheelchair:before {
          background-image: url("/assets/images/seats/wheelchair.svg");
          background-repeat: no-repeat;
          background-size: contain;
          width: #{$seats-column-size * 0.8}px;
          content: "";
        }

        &.handicapplace:before {
          background-image: url("/assets/images/seats/handicapplace.png");
          background-repeat: no-repeat;
          background-size: contain;
          width: #{$seats-column-size * 0.8}px;
          content: "";
        }

        &.wheelchairwithseat:before {
          background-image: url("/assets/images/seats/handicapseat.png");
          background-repeat: no-repeat;
          background-size: contain;
          width: #{$seats-column-size * 0.8}px;
          content: "";
        }

        &.handicapseat:before {
          background-image: url("/assets/images/seats/handicapseat.png");
          background-repeat: no-repeat;
          background-size: contain;
          width: #{$seats-column-size * 0.8}px;
          content: "";
        }

        &.companion:before {
          background-image: url("/assets/images/seats/companion.svg");
          background-repeat: no-repeat;
          background-size: contain;
          width: #{$seats-column-size * 0.8}px;
          content: "";
        }
        &.exit:before {
          background-image: url("/assets/images/elements/emergency_right.svg");
          background-repeat: no-repeat;
          background-size: contain;
          width: #{$seats-column-size * 0.8}px;
          content: "";
        }
        &.seatgroup:before {
          border-radius: 50%;
          border-width: 3px;
          border-style: solid;
          border-color: #ced5d9;
          background-color: var(--seatgroup-color);
        }

        &.screen-element {
          background-repeat: no-repeat;
          background-size: contain;
        }

        &.emergency:before {
          @extend .screen-element;
          background-image: url("/assets/images/elements/emergency_right.svg");
        }
        &.exit:before {
          @extend .screen-element;
          background-image: url("/assets/images/elements/exit_right.svg");
        }
        &.rails:before {
          @extend .screen-element;
          background-image: url("/assets/images/elements/rails_up.svg");
        }
        &.stairs:before {
          @extend .screen-element;
          background-image: url("/assets/images/elements/stairs_down.svg");
        }
        &.wall:before {
          @extend .screen-element;
          background-image: url("/assets/images/elements/wall.svg");
        }
      }
    }
  }
}
