app-screen-seats-seat {

  div.seat {
    border-radius: 0;
    background-repeat: no-repeat;
    background-size: contain;
    border: none;
    width: #{$seats-column-size}px;
    min-width: #{$seats-column-size}px;
    height: 100%;
    background-color: $light-blue!important;
    font-family: 'Font Awesome 5 Free';
    
    button {
      color:$white!important;
      font-size: 12px;
      &:before {
        width: 100%;
        height: 100%;
        font-size: 12px;
      }
    }

    // Seat type
    &.none {
      background-color: $light-blue!important;
      background-size: contain;
    }

    &.mine {
      background-color: $red!important;
    }

    &.occupied {
      background-color: $light-gray!important;
    }

    &.vip {
      background-image: url("/assets/images/seats/reclinerseat.png");
      background-size: contain;

      &.mine {
        background-image: url("/assets/images/seats/reclinerseat-selected.png");
      }

      &.occupied {
        background-image: url("/assets/images/seats/reclinerseat-sold.png");
      }
    }

    &.reclinerseat {
      background-image: url("/assets/images/seats/reclinerseat.png");
      background-size: contain;

      &.mine {
        background-image: url("/assets/images/seats/reclinerseat-selected.png");
      }

      &.occupied {
        background-image: url("/assets/images/seats/reclinerseat-sold.png");
      }
    }

    &.companion {
      background-image: url("/assets/images/seats/companion.svg");
      background-size: contain;

      &.mine {
        background-image: url("/assets/images/seats/companion-selected.svg");
      }

      &.occupied {
        background-image: url("/assets/images/seats/companion-sold.svg");
      }
      button:before {
        content: '';
      }
    }

    &.handicapseat {
      background-image: url("/assets/images/seats/handicapseat.png");
      background-size: contain;

      &.mine {
        background-image: url("/assets/images/seats/handicapseat-selected.png");
      }

      &.occupied {
        background-image: url("/assets/images/seats/handicapseat-sold.png");
      }

      button:before {
        content: '';
      }
    }

    &.handicapplace {
      background-image: url("/assets/images/seats/handicapplace.png");
      background-size: contain;

      &.mine {
        background-image: url("/assets/images/seats/handicapplace-selected.png");
      }

      &.occupied {
        background-image: url("/assets/images/seats/handicapplace-sold.png");
      }

      button:before {
        content: '';
      }
    }

    &.wheelchair {
      background-image: url("/assets/images/seats/wheelchair.svg");
      background-size: contain;

      &.mine {
        background-image: url("/assets/images/seats/wheelchair-selected.svg");
      }

      &.occupied {
        background-image: url("/assets/images/seats/wheelchair-sold.svg");
      }

      button:before {
        content: '';
      }
    }

    &.wheelchairwithseat {
      background-image: url("/assets/images/seats/handicapseat.png");
      background-size: contain;

      &.mine {
        background-image: url("/assets/images/seats/handicapseat-selected.png");
      }

      &.occupied {
        background-image: url("/assets/images/seats/handicapseat-sold.png");
      }

      button:before {
        content: '';
      }
    }

    // Seat state
    &.free {
      color: $blue;
      button:before {
        content: '';
        background: transparent;
      }
    }

    &.mine {
      color: $blue;
      fill: $orange;
      content: '';
      button {
        color: $orange;
      }
    }

    &.occupied {
      color: $blue;
      button {
        cursor: default;
      }
    }

    &.blocked {
      border: none;
      background-image: url("/assets/images/seats/blocked.png");
      button {
        cursor: default;
      }
    }

    button {
      cursor: pointer;
      display: block;
      width: 100%;
      height: 100%;
      border: none;
      background-color: transparent;
      margin: 0;
      padding: 0;

      &:focus, &.active {
        outline: none;
      }
    }
  }
}
