app-page-mvoucher {


  .price-info-block {

    &:hover {

      cursor: pointer;

    }

  }

}
