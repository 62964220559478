app-my-profile-component {

  input[type="text"], input[type="password"], .input-group-button-right, .input-group-button-left, .checkbox .cr {

    background-color: $white !important;
  }

  .btn-logout {

    font-size: 16px;

  }


  .avatar-area {

    padding-bottom: 30px;
    padding-top: 30px;

    .image-info-label{

      font-size: 0.8rem;
      color: $gray-700;

    }

    .avatar-frame {

      border: 2px solid #05C3DE ;
      width: 204px !important;
      height: 204px !important;

      .edit-button {
        width: 30px;
        height: 30px;
        border-radius: 30px;
        background-color: red;
        position: absolute;
        left: calc(50% - 15px);
        margin-top: -17px;

        background-image: linear-gradient(to right, #e0004d, #b32675 85%);
        color: white;
        text-align: center;

        .icon {

          height: 15px;
          width: 15px;
          background-color: transparent !important;
          border-radius: 0 0 0 0 !important;
          background-image: url("/assets/images/edit.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 70%;
          display: inline-block;
          padding: 14px 14px;
          cursor: pointer;
        }

        :hover {

          opacity: 0.6;
          cursor: pointer;
        }
      }
    }

    .avatar-frame, .avatar-frame img {

      background-color: white;
      width: 200px;
      height: 200px;
      border-radius: 30px 30px 30px 30px;
    }


  }

}



