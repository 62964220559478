app-cart-popup-cart-ticket-item-component {

  .container-row {

    align-items: normal !important;

  }

  .movie-poster {

    width: 6rem;
    height: 9rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;

  }

  .screening-time-range {

    font-size: .7rem;

  }

  .ticket-item {

    font-size: 0.9rem;

    .unit-price {

      font-size: .75rem;

    }

  }

  .movie-title {

    font-weight: bold;


  }

  .col-drop {
    display: flex;
    align-items: center;
    color: $pink;

    .remove-button {

      &:hover {

        opacity: 0.8;
        cursor: pointer;

      }

    }
  }

  .strike {

    padding-top: 1rem;
    border-bottom: 1px solid #9b9b9b;
    width: 100%;
    margin-bottom: .5rem;

  }

}
