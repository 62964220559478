app-user-history-order-qr-component {

  .order-history-qr {

    .box-qr {

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

    }

    .zoom {
      cursor: pointer;
      color: black;
      font-weight: bold;

      &:before {
        content: "";
        width: 22px;
        height: 22px;
        display: block;
        background: url("/assets/images/zoom_code.svg");
        text-align: center;
        margin: auto;
        border: 2px solid #9b9b9b;
        border-radius: 50%;
        padding: 15px;
        background-position: center;
        background-repeat: no-repeat;
        margin-top: 8px;
        margin-bottom: 8px;
        cursor: pointer;
      }
    }

  }

}



