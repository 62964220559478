app-personal {

  .personal-card {


    .card-header {
      padding: 1.25rem 0rem;
    }

    .card-body {
      padding: 1.25rem 0rem;
    }

    .card-footer {
      padding: 1.25rem 0rem;
    }
  }

  .form-control, .form-control:focus {

  }

  .btn-primary {

  }

}
