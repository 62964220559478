app-user-history-page {

  .user-history {


    .qr-block {
      border-style: solid;
      border-color: #d8d8d8;
      padding-left: 20px;
      border-width: 0 0 0 0 !important;
    }

    .show-toggle {

      color: #b32675;
      border-width: 1px;
      border-style: solid;
      border-color: $blue;
      line-height: 46px;
      border-radius: 15px 5px 5px 5px;
      width: 170px;
      margin-top: 20px;

    }

    .tickets-box {

      border-bottom-width: 0 !important;

    }
  }



}

