.panel {

  .btn-link {
    color:$default-color;
  }
  .open {
    display: none;
    color: $blue;
    .fa {
      font-size: 12px;
    }
  }
  .close {
    display: block;
    color: $blue;
    .fa {
      font-size: 12px;
    }
  }

  &.panel-open {

    .btn-link {
      color:$default-color;
    }

    .panel-title {
      .close {
        display: none;
        color:$gray;
      }
      .open {
        display: block;
        color:$gray;
      }
    }
  }
}
