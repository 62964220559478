app-screen-movie-movie-poster-component {
  .movie-poster {
    width: 100%;
    height: 180px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;

    .label-release {

      position: fixed;

      background: $light-blue;
      color: #FFFFFF;
      width: 70%;

      padding: 7px 7px;
      text-align: left;
      border-radius: 0px 0px 18px 0px;

      &.male {
        background-color: #004B87;
      }

    }

    .movie {

      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-items: center;
      justify-content: center;

      .movie-title {

        font-weight: normal;
        color: white;
        padding: 0rem 1rem 1rem 1rem;
        font-size: 1.3vw;
      }

    }

  }

}

*[dir="rtl"]  app-screen-movie-movie-poster-component {

  .movie-poster {

    .label-release {
      text-align: right;
      border-radius: 0px 0px 0px 18px;
    }

  }

}
