@import "~@fortawesome/fontawesome-free/scss/_variables.scss";

$primary-color:#c80037!default;
$secondary-color:#bf0b38!default;
$default-color:#BDBCBB !default;
$default-color-text:#000000 !default;

$pink: #EA5861;
$violet: #772583;

$background-header: #222A37!default;
$background-subheader:#aaaaaa !default;
$background-color:#222A37!default;

$table-border-color:#d3d3d3!default;

// seats component
$seats-columns: 40 !default;
$seats-column-size: 20!default;
$seats-column-margin: 2!default;
$seats-column-legend-size:60!default;
$seats-row-size: 20!default;
//icon
$background-icon-free: blue !default;
$background-icon-mine: red !default;
$background-icon-occupied: pink !default;

// end seats component

$link-color: #c80037!default;
$link-hover-color: #bf0b38!default;

$font-size-base: 0.8rem !default;

$tt :(
  "start":"",
  "end":""
);

$theme-colors: (
        "primary": $primary-color,
        "secondary":$secondary-color,
        "default":$default-color,
);
$enable-rounded:true;
$enable-shadows:true;
$enable-gradients:false;
