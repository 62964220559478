app-screening-list,
*[app-screening-list] {
  display: flex;
  flex-flow: column;
  height: 100%;
  flex: 1 0 auto;

  .main-container {
    flex: 1 0 auto;
  }

  @media (min-width: 1284px) {
    .container {
      max-width: 90%;
    }
  }

  .table {
    td,
    th {
      border-color: #ced4da;
      vertical-align: middle;
      padding: 5px 10px;
    }
  }
}
