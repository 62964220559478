app-screen-ticket-count-general-admission {

  .header {
    font-weight: bold;
    color: #FFFFFF;
  }

  .increment {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    text-align: center;
    font-size: 12px;
    color: $dark-blue;
    background-color: $light-blue;

    &.disabled {
      background-color: $gray !important;
      cursor: pointer;
    }
  }

  .count {
    display: inline-block;
    width: 2rem;
    text-align: center;
  }
}
