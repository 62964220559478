app-header {
  #app-header {
    background-color: $background-header;
    .cinema-title {
      padding-top: 28px;
      font-size: 20px;
      line-height: 20px;
    }
  }
  #app-sub-header {
    padding: 15px;
    background-color: $background-subheader;
    .clock-countdown {
      .fa {
        margin-right:4px; ;
      }
    }
  }
}