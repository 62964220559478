app-user-remind {
  .card-footer {
    padding: 0;
  }

  .card-body {
    padding: 0;
  }

  .card-header {
    padding: 20px 0px;
  }

  h3 {
    color: #4a4a4a;
    font-weight: bold;
    margin-bottom: 10px;
  }
  h5 {
    color: #4a4a4a;
    font-weight: bold;
  }
}
