app-sidebar {
  .sidebar {
    background-color: #191f29;

    &-header {
      background-color: #13171f;
      text-align: center;
      font-size: 1.25rem;
      font-weight: bold;
    }
    &-body {
    }
    &-footer {
    }

    .top-label {
      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
    }
    .label {
      font-size: 1rem;
    }
  }
}
