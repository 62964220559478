app-user-history-order-qr-component {

  .order-history-qr {


    .zoom {
      cursor: pointer;
      color: black;
      font-weight: bold;

      &:before {

        border: none !important;;
      }
    }

    .qr {

      border-width: 0 0 0 1px;
      border-style: solid;
      border-color: #d8d8d8;
      padding-left: 20px;

    }

  }

}

*[dir="rtl"] {

  app-user-history-order-qr-component {

    .order-history-qr {


      .qr {

        border-width: 0 1px 0 0;

      }

    }

  }


}
