app-page-login {

  .card-body {
    padding: 0px 30px;
  }

  .next-info {
    background-color: #dff7fb;
    margin: 0px -10px;
    padding: 20px 32px;

    span {
      font-weight: bold;
      color: #004b87;
      text-decoration: underline;
    }
  }
  .message {
    margin:10px -30px;
  }
}
