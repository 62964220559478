app-page-catering {
  app-popup.catering-article-popup .popup {
    width: 500px;

    &.modifier div {
      font-weight: normal;
    }
    &.modifier div:first-of-type {
      font-weight: bold;
    }
  }

  .col-summary {
    padding-right: 1rem;
    border-width: 0 0 0 1px;
    border-style: solid;
    border-image: linear-gradient(to top, #e0004d, #b32675 33%, #004b87 68%, #0280ae 86%, #05c3de);
    transform: rotate(-360deg);
    border-image-slice: 1;

    .col-summary-close {
      display: none;
    }
  }

  .col-catering {
    .placeholder-logo {

      width: 100%;
      height: 200px;
      background-image: url("/assets/images/catering_logo.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

[dir="rtl"] {
  app-page-catering {
    .col-summary {
      border-width: 0 1px 0 0 !important;
    }
  }
}
