app-user-history-order-price-component {

  .order-history-price {

    .cell {

      padding-left: 0 !important;
      padding-right: 0 !important;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;

      .subtitle {

        color: $blue;
        font-weight: 900;
      }

    }

    .box {

      padding-top: 10px;
      padding-bottom: 15px;


    }

    .no-padding {

      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .price {

      font-weight: 700;

    }

    .total-price {



    }

    .simple-price-padding {

      margin-top: 24px;
    }

    .total-price-info {

      font-size: 0.7rem;
      display: block;
      color: gray;

    }

  }

}
