app-page-payment {
  display: flex;
  flex-flow: column;
  height: 100%;
  flex: 1 0 auto;

  .container {
    flex: 1 0 auto;
    border-bottom: 6px solid $dark-blue;
  }

  app-popup {
    .body {
      height: 70% !important;
    }
    .seat-popup {
      text-align: center;
      display: flex;
      flex-direction: column;
      height: 80%;
      .popup-ticket-type {
        display: block;
        color: $light-blue;
        font-size: 1.8rem;
      }

      .popup-seat-type {
        display: block;
      }

      .popup-close-button {
        margin-top: auto;
      }
    }
  }
  .selectedMethod {
    background-color: $dark-blue !important;
  }

  .infoAbove {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.2rem;
    text-align: center;
  }

  @media (min-width: 1100px) {
    .fee-agreement-block {
      position: relative;
      bottom: 30px;
    }
  }

  .hrline-block {
    border: none !important;
  }

  .payment-block {
    transition: all 0.3s ease-out 0s;
    .pay-method-container {
      display: flex;
      justify-content: center;
    }
  }

  .pay-button {
    background-color: #0062cc;
  }

  iframe {
    overflow: hidden !important;
  }
}

.fee-agreement-block {
  .fee-agreement-label {
    font-weight: bold;
  }
}
