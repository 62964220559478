app-user-history-page {
  h3 {
    color: $default-color;
    font-weight: 900;
  }

  h4 {
    padding-top: 20px;
    color: $gray;
  }

  .last-seen-separator {
    display: flex;

    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#afafaf+0,c6c6c6+52,b2b2b2+100 */
    background: rgb(175, 175, 175); /* Old browsers */
    background: -moz-linear-gradient(left, rgba(175, 175, 175, 1) 0%, rgba(198, 198, 198, 1) 52%, rgba(178, 178, 178, 1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgba(175, 175, 175, 1) 0%, rgba(198, 198, 198, 1) 52%, rgba(178, 178, 178, 1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to right,
      rgba(175, 175, 175, 1) 0%,
      rgba(198, 198, 198, 1) 52%,
      rgba(178, 178, 178, 1) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#afafaf', endColorstr='#b2b2b2',GradientType=1 ); /* IE6-9 */

    height: 50px;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;

    & span:first-of-type {
      font-family: $font-family-heading;
      color: $white;
      font-size: 1.4rem;
      font-weight: 900;
    }
  }

  .booking-header {
    font-family: $font-family-base;

    margin-bottom: 14px;
    font-size: 1.6rem;

    > .label {
    }

    > .booking-id {
      font-weight: 900;
    }
  }

  .booking-footer {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .booking-container {
    .booking-footer {
      &:last-of-type {
        display: none;
      }
    }
  }

  .last-seen {
    padding: 25px 0 20px 0;
  }
}
