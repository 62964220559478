app-screen-legend {
  margin: 0rem;
  .legend-container {
    .items {
      display: block;
      span {
        display: inline-block;
      }
    }
  }
}
