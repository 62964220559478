@font-face {
  font-family: "Montserrat";
  src: url("/assets/fonts/montserrat-v25-latin-regular.ttf");
}

//import default
@import "variables";
@import "mixins";
@import "../default/main";

// bootstrap
@import "bootstrap/bootstrap";

@import "body";

// layout
@import "core/layout/layout/layout.component";
@import "core/layout/header/header";
@import "core/layout/footer/footer";
@import "core/layout/template/sidebar/sidebar";

@import "form";

// core pages
@import "core/page/screen/screen";
@import "core/page/screening/screening";
@import "core/page/personal-and-payment/personal-and-payment";
@import "core/page/order-summary/order-summary";
@import "core/page/user/login/login";
@import "core/page/user/register/register";
@import "core/page/user/remind/remind";
@import "core/page/message/message";
@import "core/page/user/preferences/preferences";
@import "core/page/user/history/history";
@import "core/page/user/setpassword/setpassword";
@import "core/page/user/my-profile/my-profile";
@import "core/page/catering/catering.component";
@import "core/page/mvoucher/mvoucher.component";
@import "core/page/payment/payment.component";
@import "core/page/basket/basket.component";
@import "core/page/personal/personal.component";

// core components
@import "core/component/screening/list/list";
@import "core/component/screening/list/component/item/item";
@import "core/component/screening/select-date/select-date";
@import "core/component/countdown/countdown.component";
@import "core/component/screen/legend/legend";
@import "core/component/screen/seats/seats";
@import "core/component/screen/seats/seat/seat";
@import "core/component/screen/seats-selected-list/seats-selected-list";
@import "core/component/screen/seats/multiseat/multiseat";
@import "core/component/screen/movie/movie";
@import "core/component/screen/movie/movie-poster/movie-poster";
@import "core/component/screen/navigation/navigation";
@import "core/component/screen/ticket-count-general-admission/ticket-count-general-admission.component";
@import "core/component/steps/steps";
@import "core/component/personal-and-payment/navigation/navigation";
@import "core/component/personal-and-payment/personal-and-payment";
@import "core/component/personal/personal";
@import "core/component/user/login/login";
@import "core/component/user/register/register";
@import "core/component/user/remind/remind";
@import "core/component/user/setpassword/setpassword";
@import "core/component/user/navigation/navigation";
@import "core/component/user/my-profile/my-profile";
@import "core/component/user/history/history";
@import "core/component/user/history/order";
@import "core/component/user/history/order-price";
@import "core/component/user/history/order-qr";
@import "core/component/user/history/order-poster";
@import "core/component/user/history/order-movie";
@import "core/component/user/history/order-seats";
@import "core/component/user/history/order-tickets-ga";
@import "core/component/voucher/voucher-selector/voucher-selector.component";
@import "core/component/voucher/voucher-card/voucher-card.component";
@import "core/component/voucher/voucher-card/voucher-card-input/voucher-card-input.component";
@import "core/component/voucher/voucher-card/voucher-card-summary/voucher-card-summary.component";
@import "core/component/basket/basket-list/basket-list.component";
@import "core/component/loyalty/loyalty-payment-list";
@import "core/component/user/account-items/account-items";

@import "core/component/payment/credit-card/credit-card";
@import "core/component/payment/proxy/provider/intercard/intercard-payment-provider.component";
@import "core/component/order/movie-details/movie-details";
@import "core/component/order/tickets-summary/tickets-summary";
@import "core/component/order/order-summary/order-summary";
@import "core/component/order/value-summary/value-summary";
@import "core/component/order/seats-summary/seats-summary";
@import "core/component/order/tickets-summary-ga/tickets-summary-ga";
@import "core/component/user/preferences/favorite-cinema/favorite-cinema";
@import "core/component/user/area-navigation/area-navigation";

@import "core/component/catering/article/article.component";
@import "core/component/catering/article-popup/article-popup.component";
@import "core/component/catering/article-group-container/article-group-container.component";
@import "core/component/book-movie-list/book-movie-list";
@import "core/component/policy/policy";

// shared components
@import "shared/component/loading/loading";
@import "shared/component/login/login";
@import "shared/component/login/status/status";
@import "shared/component/message/message";
@import "shared/component/infobox/infobox";
@import "shared/component/password-strength-meter/password-strength-meter";
@import "shared/component/international-phone/international-phone";
@import "shared/component/app-contributor/app-contributor";
@import "shared/component/choice-slider/choice-slider";
@import "shared/ui/dropdown.component";
@import "shared/ui/tabs";
@import "shared/component/user/app-user-page";
@import "shared/component/popup/popup.component";
@import "shared/component/ui/radio-button/radio-button.component";
@import "shared/component/ui/numeric-slider/numeric-slider";
@import "shared/component/ui/numeric-stepper/numeric-stepper";
@import "shared/component/zoomable/zoomable-layer/zoom-able-layer.component";
@import "shared/component/zoomable/zoomable-header/zoom-able-header.component";
@import "shared/component/gift-card/gift-card";

@media (max-width: 576px) {
  @import "xs/core/component/screening/select-date/select-date";
  @import "xs/core/component/screening/list/list";
  @import "xs/core/component/screening/list/component/item/item";
  @import "xs/core/component/countdown/countdown.component";
  @import "xs/core/page/personal-and-payment/personal-and-payment";
  @import "xs/core/page/screen/screen";
  @import "xs/core/page/message/message";
  @import "xs/core/page/user/login/login";
  @import "xs/core/page/user/register/register";
  @import "xs/core/page/user/remind/remind";
  @import "xs/core/page/user/setpassword/setpassword";
  @import "xs/core/page/order-summary/order-summary";
  @import "xs/core/page/catering/catering.component";
  @import "xs/core/page/mvoucher/mvoucher.component";
  @import "xs/core/component/personal/personal";
  @import "xs/core/screen/movie/movie";
  @import "xs/core/screen/legend/legend";
  @import "xs/core/component/user/login/login";
  @import "xs/core/component/user/register/register";
  @import "xs/core/component/user/remind/remind";
  @import "xs/core/component/user/setpassword/setpassword";
  @import "xs/core/shared/infobox/infobox";
  @import "xs/core/_form.scss";
  @import "xs/core/component/user/history/order-qr";
  @import "xs/core/component/user/history/history";
  @import "xs/core/component/user/history/order-price";
  @import "xs/core/component/user/history/order-seats";
  @import "xs/core/component/order/seats-summary/seats-summary";
  @import "xs/core/component/user/preferences/favorite-cinema/favorite-cinema";
  @import "xs/core/component/user/history/order";
  @import "xs/core/component/screen/movie/movie";
  @import "xs/core/component/basket/basket-list/basket-list.component";
  @import "xs/core/component/order/tickets-summary/tickets-summary.component";
  @import "xs/core/component/catering/article-popup/article-popup.component";
  @import "xs/core/component/screen/legend/legend.component";
  @import "xs/core/component/catering/article-group-container/article-group-container.component";

  //shared components
  @import "xs/core/shared/international-phone/international-phone";
  @import "xs/core/shared/popup/popup";
}

@media (min-width: 576px) and (max-width: 766px) {
  @import "sm/core/page/screen/screen";
  @import "sm/core/page/personal-and-payment/personal-and-payment";
  @import "sm/core/page/order-summary/order-summary";
  @import "sm/core/page/catering/catering.component";
  @import "sm/core/page/mvoucher/mvoucher.component";
  @import "sm/core/component/user/history/order-qr";
  @import "sm/core/component/user/history/history";
  @import "sm/core/component/user/history/order-price";
  @import "sm/core/component/user/history/order-seats";
  @import "sm/core/component/order/seats-summary/seats-summary";
  @import "sm/core/component/user/preferences/favorite-cinema/favorite-cinema";
  @import "sm/core/page/user/login";
  @import "sm/core/page/user/register";
  @import "sm/core/page/user/remind";
  @import "sm/core/page/user/setpassword";
  @import "sm/core/component/user/login/login";
  @import "sm/core/component/user/register/register";
  @import "sm/core/component/user/history/order";
  @import "sm/core/component/order/tickets-summary/tickets-summary.component";
  @import "sm/core/component/catering/article-popup/article-popup.component";
  @import "sm/core/component/screen/legend/legend.component";
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991px) {
  @import "md/core/page/order-summary/order-summary";
  @import "md/core/page/user/login";
  @import "md/core/page/user/register";
  @import "md/core/page/user/remind";
  @import "md/core/page/mvoucher/mvoucher.component";
  @import "md/core/component/user/login/login";
  @import "md/core/component/user/register/register";
  @import "md/core/component/user/history/order";
  @import "md/core/component/catering/article/article.component";
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1438px) {
  @import "lg/core/page/user/login";
  @import "lg/core/page/user/register";
  @import "lg/core/page/user/remind";
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1440px) {
}
