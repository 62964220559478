app-zoom-able-layer {
  width: 100%;
  height: 50%;
  top: 0;
  left: 0;
  display: block;
  .layer {
    width: 100%;
    height: 100%;
    cursor: zoom-in;
  }
  .layer-disabled {
    cursor: default !important;
  }
}
