// colors
$dark-blue: #222a37;
$light-blue: #00aeef;
$blue: #00aeef;
$yellow: #ffb100;
$gray: #a6a6a6;
$white: #ffffff;
$black: #000000;
$red: #ff0000;
$light-gray: #c1c1c1;
$dark-gray: #39404b;
$orange: #eb991e;
$dark: #191f29;

$error-color: #d7403a;

$font-size-base: 0.813rem;
$font-family-base: "Montserrat";
$font-family-heading: "Montserrat";

$primary-color: $yellow;
$default-color: $white;
$default-color-text: $white;
$secondary-color: $light-blue;

$background-header: $dark-blue;
$background-color: $dark-blue;
$background-footer: $dark-blue;

$seats-columns: 60;
$seats-column-size: 23;
$seats-column-margin: 1;
$seats-row-size: 23;

$background-icon-free: transparent;
$background-icon-mine: transparent;
$background-icon-occupied: transparent;

$table-border-color: #3e3e3e;

// bootstrap -> card
$card-bg: transparent;
$card-border-width: 0;
$carousel-control-color: #17c3de;

$footer-height: 30px;

$interaction-gray: #cfd5d9;
$dark-interaction-gray-2-hover: #cfd5d9;
$dark-interaction-gray-2: #b1b9be;
$dark-interaction-gray-2-color: #141414;
$dark-interaction-gray-hover: #cfd5d9;
$dark-interaction-gray: #b1b9be;

$google-pay-button-background: #ffffff;
$google-pay-button-background-hover: #eff7f8;
$google-pay-button-border: #ffffff;
$google-pay-button-border-hover: #ffffff;
$google-pay-button-color: #141414;

$apple-pay-button-background: #050707;
$apple-pay-button-background-hover: #4f555b;
$apple-pay-button-border: #050707;
$apple-pay-button-border-hover: #4f555b;
$apple-pay-button-color: #ffffff;

$btn-transparent: transparent;
$interaction-blue: #086adb;
$dark-interaction-blue-2-hover: #147df5;
$dark-interaction-blue-2: #0a71e9;
$dark-interaction-blue-2-color: #ffffff;
$dark-interaction-blue-hover: #5ca3ff;
$dark-interaction-blue: #4294ff;
$special-grid-columns: 16;
$voucher-color: #6dd55a;

$defaultborder: 6px solid $light-blue;
