app-user-setpassword {

  .card-footer {
    padding: 0;
  }

  .card-body {
    padding: 0;
  }

  .card-header {
    padding: 20px 0px;
  }


}
