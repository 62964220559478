*[dir="rtl"] {

  app-user-history-order-component {

    .user-history {

      .qr-block {

        border-width: 0 0 0 0 !important;;
      }

    }
  }

}
