app-countdown,
app-simple-countdown {
  span.clock-label {
    font-size: 1.4rem;
    font-weight: bold;
  }
  span.clock-countdown {
    color: white;
    font-size: 1.4rem;
    font-weight: bold;
    border: none !important;
    height: unset !important;
    width: unset !important;
    line-height: 1.4rem !important;
  }
}
