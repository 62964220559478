html,body {
  min-height: 100vh;
  height: 100%;
}

body {
  overflow-y:scroll;
  background-color: $background-color;
  color:$default-color-text;

  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.card {
 border-radius: 0px;
}

.btn {
  border-radius: 0;
  font-weight: 400;
}

.table {
  border-collapse: collapse;
  td ,th{
    padding:0.3rem;
    vertical-align: middle;
    border-bottom:1px solid $table-border-color;
  }
  thead th {
    padding:0.7rem 0.3rem;
    border-bottom:1px solid $table-border-color;
  }
}

#app-header {
  background: #333333;
}