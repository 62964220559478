app-screen-seats-multiseat {
  width: 100%;
  display: flex;
  justify-content: center;

  div.seat {
    background-color: $light-blue;
    background-repeat: no-repeat;
    background-position: center 0;
    background-size: cover;
    font-family: "Font Awesome 5 Free";
    width: #{$seats-row-size}px;
    height: #{$seats-row-size}px;

    &:first-of-type {
      background-repeat: no-repeat;
      background-position: left 0;
      background-size: cover;
    }

    &:last-of-type {
      background-repeat: no-repeat;
      background-position: -22px 0;
      background-size: cover;
    }

    &:only-of-type {
      background-color: $light-blue;
      background-position: center 0;
    }

    &.mine {
      background-color: $red;
    }

    &.occupied {
      background-color: $light-gray;
    }

    button {
      cursor: pointer;
      display: block;
      width: 100%;
      height: 100%;
      border: none;
      background-color: transparent;
      margin: 0;
      padding: 0;
      color: $white !important;
      font-size: 12px;
      &:before {
        width: 100%;
        height: 100%;
        font-size: 12px;
      }

      &:focus,
      &.active {
        outline: none;
      }
    }

    &.lounge {
      height: #{$seats-row-size - 2}px;
    }
    &.couch {
      height: #{$seats-row-size}px;
    }

    &.lounge,
    &.couch {
      width: #{$seats-row-size}px; //22px - max
      display: inline-block;
      border-radius: 0;
      margin: 0;

      &:first-child,
      &:last-child {
        border-radius: 0;
      }

      &:first-of-type,
      &:last-of-type {
        width: #{$seats-row-size}px;
      }

      &:only-of-type {
        width: #{$seats-row-size - 1}px;
      }

      &.free {
        background-color: $light-blue !important;
        content: "";
      }

      &.mine {
        background-color: $red !important;
        content: "";
      }

      &.occupied {
        background-color: $light-gray !important;
        button {
          cursor: default;
        }
      }
    }
  }
}
