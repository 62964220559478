app-page-screening,
*[app-page-screening] {
  display: flex;
  flex-flow: column;
  height: 100%;
  flex: 1 0 auto;

  .card {
    border: none !important;
  }

  .movie-poster {
    min-height: 100%;
    aspect-ratio: 11/17;
    min-width: 100%;

    img {
      width: auto;
      object-position: center;
      display: -ms-inline-flexbox;
      object-fit: cover;
      display: block;
      margin: 0 auto;
      height: 100% !important;
    }
  }
  select.form-control {
    min-height: 46px;
  }
}
