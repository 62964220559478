.legend-container {
  padding: 0px;
  width: 100%;
  border: 1px solid #e3e3e3;
  padding: 10px 20px;

  @include size-list;

  .items {
    margin-top: 15px;
    span {
      display: inline-block;
      padding: 0px 20px 0px 0px;
      margin: 10px 0px;
      &:before {
        font-family: 'Font Awesome 5 Free';
        display: inline-block;
        height: $seats-row-size+px;
        line-height: $seats-row-size+px;;
        width: $seats-column-size+px;
        margin: 0px 10px 0px 0px;
        vertical-align: middle;
        text-align: center;
      }
      &.free:before {
        @extend .icon, .free;
      }
      &.occupied:before {
        @extend .icon, .occupied;
      }
      &.mine:before {
        background-color: $background-icon-mine!important;
        content: fa-content($fa-var-user);
      }
      &.couch:before {
        @extend .icon, .free;
        width: $seats-column-size*2+px;
        border-radius: 20px;
      }

    }
  }
}
