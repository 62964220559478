app-page-mvoucher {
  .progress-ring__circle {
    stroke-dasharray: 10 20;
  }

  .progress-ring__circle {
    transition: stroke-dashoffset 0.35s;
  }

  .progress-ring__circle {
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }

  .header-row {
    height: 10rem;

    .header-sub {
      align-items: center;

      .change-language {
        color: #96989a;

        &:hover {
          cursor: pointer;
          opacity: 0.8;
        }
      }
    }
  }

  .info-frame {
    background-color: #f5f5f5;

    .carousel-block {
      border-right-color: #e0004e;
      border-right-style: solid;
      border-right-width: 2px;
    }

    .info-root-header {
      color: #e0004e;
      font-size: 2.9rem;
      font-weight: bold;
    }

    .info-header {
      color: #17c3de;
      font-size: 2.1rem;
      font-weight: bold;
    }
    .experience-info-header {
      color: #7c2482;
      font-size: 2.1rem;
      font-weight: bold;
    }
  }

  .foot-frame {
    background-color: #343434;
    color: white;

    .foot-header {
      text-align: left;
      font-size: 2.7rem;
      font-weight: bold;
    }
  }

  .main-frame {
    background-color: white;

    .head-info {
      color: #e0004e;
      font-size: 4rem;
    }

    .price-info-block {
      width: 100%;
      border-color: #e0004e;
      border-width: 4px;
      border-radius: 17px;
      border-style: solid;
      color: #e0004e;
      font-size: 1.4rem;
      font-weight: bold;
    }

    .ticket-block-disabled {
      opacity: 0.5;
    }

    .experience-block {
      border-color: #17c3de !important;
    }

    .carousel-control-next-icon {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%2317c3de' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
    }

    .item-fr-experience {
      color: #17c3de !important;
    }

    .header-experience {
      border-color: #17c3de !important;
      color: #17c3de !important;

      h4 {
        color: #17c3de !important;
      }
    }

    .ticket-info-block {
      width: 100%;
      border-width: 4px;
      border-radius: 17px;
      border-style: solid;
      border-color: #7c2482;

      .header {
        width: 100%;
        border-width: 4px;
        border-bottom-style: solid;
        border-color: #7c2482;
      }

      .item-block {
        color: #96989a;
        font-size: 1.2rem;

        .item-fr {
          color: #7c2482;
        }
      }
    }
  }

  .selector {
    justify-content: center;

    .increment-summary {
      border: 1px solid #7c2482;
      background-color: white;
      color: #7c2482;
      width: 3rem;
      height: 3rem;
      justify-content: center;
      align-items: center;
    }

    .increment-left {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      border: 1px solid #7c2482;
      background-color: #7c2482;
      width: 3rem;
      height: 3rem;
      color: white;
      justify-content: center;
      align-items: center;

      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }

    .increment-right {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      background-color: #7c2482;
      border: 1px solid #7c2482;
      width: 3rem;
      height: 3rem;
      color: white;
      justify-content: center;
      align-items: center;

      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }
  }

  .buy-area {
    .btn-primary {
      background: #7c2482 !important;
      border-radius: 26px;
      width: 163px;
      box-shadow: 10px 10px 13px -13px rgba(0, 0, 0, 0.75);
    }
  }

  .timer-block {
    min-height: 17%;
    background: rgb(188, 12, 95);
    background: linear-gradient(140deg, rgba(188, 12, 95, 1) 0%, rgba(25, 67, 133, 1) 52%, rgba(0, 212, 255, 1) 100%);

    .btn-primary {
      background: #e0004e !important;
      border-radius: 26px;
      width: 230px;
      box-shadow: 10px 10px 13px -13px rgba(0, 0, 0, 0.75);
    }

    .hand-block {
      background-image: url("/assets/images/tickets_hand.png");
      width: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 100%;
      min-height: 23rem;
    }
  }
  .center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

  .col-brand {
    clear: both;

    .brand-container {
      //padding-right: 2rem;
      text-align: right;
      img {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .language-button {
    text-align: left;
  }
  .foot-description {
    text-align: left;
  }
  .bannerText {
    text-align: left;
  }
  @media screen and (min-width: 1400px) {
    .progress-ring-lg {
      display: block;
    }
    .progress-ring-md {
      display: none;
    }
    .progress-ring-sm {
      display: none;
    }
  }
  @media screen and (max-width: 1400px) {
    .progress-ring {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    .progress-ring-lg {
      display: none;
    }
    .progress-ring-md {
      display: block;
    }
    .progress-ring-sm {
      display: none;
    }
    .progress-ring__label {
      font-size: 15px;
      stroke-width: 1;
    }
    .progress-ring__value {
      font-size: 50px;
      stroke-width: 3;
    }
  }
  @media screen and (max-width: 720px) {
    .progress-ring {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    .progress-ring-lg {
      display: none;
    }
    .progress-ring-md {
      display: none;
    }
    .progress-ring-sm {
      display: block;
    }
    .progress-ring__label {
      font-size: 10px;
      stroke-width: 1;
    }
    .progress-ring__value {
      font-size: 30px;
      stroke-width: 0;
    }
  }
  *[dir="rtl"] {
    .brand-container {
      text-align: left !important;
    }
    .bannerText {
      text-align: right !important;
    }
    .language-button {
      text-align: right !important;
    }
    .foot-header {
      text-align: right !important;
    }
    .foot-description {
      text-align: right !important;
    }
    .increment-left {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    .increment-right {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .copyright {
      text-align: left !important;
    }
  }
}
