app-user-history-order-seats-component {

  .seats-summary-container {
    display: flex;
    flex-direction: row;

    padding-top: 0.2rem;

    .icon {

      padding-top: 3.2em;
    }

    .count {
      margin-bottom: 0rem;
      padding-left: .25rem;
    }

    .list {
      line-height: 12px;
      margin-bottom: 0;
      word-break: break-word;
    }

    &:before {

      width: 2rem;
      background-image: url("/assets/images/select_seats.svg");
      background-position: center left;
      background-repeat: no-repeat;
      background-size: contain;
      content: '';
      padding: 0 0 0 0;
      margin: 0px 7px 0px 0px;

    }

  }

}
