app-personal-and-payment-navigation {
  display: block;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: $white;
  margin-top: 3rem;

  .screen-navigation-container {
    background: linear-gradient(to right, #05c3de, #004b87);
    height: 5rem;

    .container {
      height: 100%;
    }

    .row {
      height: 100%;
    }

    .col {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .order-summary {
      padding: 1.375rem 0.75rem;
      flex-grow: 1;
      font-size: 1.25rem;
    }

    .btn-navigation {
      background: transparent;
      border: none;
      font-size: 1.5rem;
      color: $white;
    }
  }
}
