app-user-history-order-component {

  .user-history {

    .card-body {

      margin-left: 0 !important;
      margin-right: 0 !important;

    }

  }
}


*[dir="rtl"] {

  app-user-history-order-component {

    .user-history {

      .qr-block {

        border-width: 0 0 0 0 !important;;
      }

    }
  }

}
