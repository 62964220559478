// Variable overrides
$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";

@import "variables";
 
/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/scss/bootstrap";

@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";

@import "datepicker";
@import "mixin";
@import "body";

//components

@import "schared/component/loading.component";
@import "core/component/countdown/countdown.component";

@import "core/layout/header/header";
@import "core/layout/footer/footer";


@import "page/screening/screening";

@import "core/component/screening/select-date/select-date";
@import "core/component/language-change/language-change";
@import "core/component/screening/list/component/item/item";
@import "core/component/screening/list/list";
@import "core/component/screen/seats/seat/seat";
@import "core/component/screen/ticket-count-selector/ticket-count-selector";
@import "core/component/screen/seats/seats";
@import "core/component/screen/seats/header";
@import "core/component/screen/seats/legend";
@import "core/component/screen/seats/seats-multiseat/seats-multiseat";
@import "core/component/cinema/cinema";
@import "core/component/personal/personal.component";
@import "core/component/cart/cart-area/cart-area.component";
@import "core/component/cart/popup-cart/popup-cart.component";
@import "core/component/cart/popup-cart/popup-cart-ticket-item/popup-cart-ticket-item.component";
@import "core/component/voucher/voucher-selector/voucher-selector.component";

@import "core/component/user/info/info";


@import "modal";

@media (max-width: 576px) {

  @import "xs/page/screening/screening";
  @import "xs/core/component/cart/popup-cart/popup-cart.component";
}

@media (min-width: 576px) and (max-width: 766px) {

  @import "sm/page/screening/screening";
  @import "sm/core/component/cart/popup-cart/popup-cart.component";
  @import "sm/core/component/cart/popup-cart/popup-cart-ticket-item/popup-cart-ticket-item.component";
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 990px){

  @import "md/page/screening/screening";
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1438px){

  @import "lg/component/screening/list/component/item/item";
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1440px) {

  @import "lg/component/screening/list/component/item/item";
}
