app-page-basket {
    display: flex;
    flex-flow: column;
    height: 100%;
    flex: 1 0 auto;
  
    .container {
      flex: 1 0 auto;
      border-bottom: 6px solid $dark-blue;
    }
}