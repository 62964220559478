app-page-register {
  .card-header {
    padding: 0;

    img {
      width: 100%;
    }
  }

  .card-body {
    padding: 1.25rem;
  }

  .next-info {
    background-color: #dff7fb;
    margin: -32px -40px;
    padding: 20px 32px;

    span {
      font-weight: bold;
      color: #004b87;
      text-decoration: underline;

    }
  }

  .message {
    margin:10px -20px;
  }

}
