app-page-mvoucher {

  .header-row {

    height: 7rem !important;;
  }


  .price-info-block {

    &:hover {

      cursor: pointer;

    }

  }

  @media screen and (max-width: 360px) {

    .header-row {

      height: 6rem !important;
    }

  }

  @media screen and (max-width: 314px) {

    .header-row {

      height: 5rem !important;
    }

  }

  @media screen and (max-width: 260px) {

    .header-row {

      height: 4rem !important;
    }

  }


}
