app-countdown {
  span.clock-label {
    font-size: 1rem;
  }
  span.clock-countdown {
    color: white;
    font-size: 1rem;
    height: auto;
    width: auto;
    font-weight: bold;

  }
}
