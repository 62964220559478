app-language-change {
  .language-container {
    height: 66px;
    line-height: 66px;
    text-align: right;
    .btn {
      padding:0px 10px;
      vertical-align: sub;
      font-size: 14px;
    }
    &.current {
      padding-top: 10px;
      text-align: left;
      color:$default-color-text
    }

  }
}
