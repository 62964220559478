app-order-seats-summary {
  display: block;

  .seats-summary-container {
    padding-top: 2rem;
    display: flex;
    flex-direction: row;

    .icon {

      padding-top: 3.2em;
    }

    .count {
      margin-bottom: 0rem;
      padding-left: .25rem;
    }

    .list {
      line-height: 12px;
      margin-bottom: 0;
      word-break: break-word;

      .seat {
        font-size: 1.25rem;
        padding: 0 .25rem;
        color: $dark-blue;
        font-weight: bold;
        line-height: 1.5rem;
        display: inline-block;
      }

      .seat:after {
        content: ',';
      }

      .seat:last-child:after {
        content: '';
      }
    }

  }

  .seats-summary-container:before {
    width: 2rem;
    background-image: url("/assets/images/select_seats.svg");
    background-position: center left;
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    padding: 0 0 0 0;
    margin: 0px 7px 0px 0px;
  }
}
