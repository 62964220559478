.show-hide-password-button {

  border: 1px solid #b6b6b6;
  background-color: transparent;
  border-top-right-radius: 2em;
  border-bottom-right-radius: 2em;
  border-left-width: 0;
  color: #b6b6b6;
  font-size: 16px;
  padding-top: 1.1rem;
  padding-right: 0.9rem;
  padding-bottom: 1.1rem;
  padding-left: 0.9rem;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
