app-page-login {
  .card-header {
    padding: 0;

    img {
      width: 100%;
    }
  }

  .card-body {
    padding: 1.25rem;
  }

  .message {
    color: #BB0000;
    font-weight: bold;
    border-top:0px solid #BB0000;
    border-bottom:0px solid #BB0000;
    background: #ffd6d6;
    padding: 20px;
    margin:10px -20px;
    text-align: center;

  }

  .login-check-label {

    font-size: 0.9rem !important;;
  }
}
