app-footer {
  flex-shrink: 0;
  .footer-area {
    background-color: #222A37;
    font-size: 0.8rem;
    padding: 12px 0;
    margin: 0 auto;
  }
  .footer-brand {
    height: 20px;
    display: inline-block;
    img {
      height:100%!important;
    }
  }
  .copyRightText {
    color: white;
    font-size: 13px;
    padding-top: 1px;
  }
}
