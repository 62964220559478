app-page-register {
  .card-header {
    padding: 0;

    img {
      width: 100%;
    }

    h3 {
      font-size: 1.5rem !important;
    }
  }

  .card-body {
    padding: 0px 120px;
  }

  .next-info {
    background-color: #dff7fb;
    margin: -14px -140px;
    padding: 20px 120px;

    span {
      font-weight: bold;
      color: #004b87;
      text-decoration: underline;
    }
  }

  .register-button {
    width: 100%;
  }
}
