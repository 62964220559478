app-page-screening,
*[app-page-screening] {
  h4 {
    height: 66px;
    line-height: 66px;
  }

  app-screen-tag {
    height: 42px;
  }

  .movie-poster {
    img {
      width: 100%;
      height: auto;
      display: inline-flex;
    }

    .label-release {
      position: fixed;

      background: #772583;
      color: #ffffff;
      width: 70%;

      padding: 7px 7px;
      text-align: left;
      border-radius: 0px 0px 18px 0px;

      &.male {
        background-color: #004b87;
      }
    }

    .movie {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-items: center;
      justify-content: center;

      .movie-title {
        font-weight: normal;
        color: white;
        padding: 0rem 1rem 1rem 1rem;
        font-size: 1.4rem;
      }
    }
  }
}

*[dir="rtl"] app-page-screening {
  .movie-poster {
    .label-release {
      text-align: right;
      border-radius: 0px 0px 0px 18px;
    }
  }
}

@media (max-width: 976px) {
}
