app-user-preferences-page {
  h3 {
    color: $default-color;
    font-weight: 900;
  }

  h4 {
    padding-top: 20px;
    color: $gray;
  }

  .strike {
    padding-top: 20px;
    display: block;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    font-size: 1.4rem;
    > span {
      position: relative;
      display: inline-block;
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        width: 9999px;
        height: 1px;
        background: $gray-600;
      }
      &:before {
      }
      &:after {
        left: 100%;
        margin-left: 15px;
      }
    }
  }
}

*[dir="rtl"] {
  app-user-preferences-page {
    .strike {
      padding-top: 20px;
      display: block;
      text-align: right;
      overflow: hidden;
      white-space: nowrap;
      font-size: 1.4rem;
      > span {
        position: relative;
        display: inline-block;
        &:after {
          display: none;
        }
        &:before {
          content: "";
          position: absolute;
          top: 50%;
          width: 9999px;
          height: 1px;
          background: $gray-600;
        }
        &:before {
          right: 100%;
          margin-right: 15px;
        }
      }
      &:after {
      }
    }
  }
}
