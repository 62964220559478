app-page-screen {
  .col-screen {
    padding-left: 15px;
    padding-right: 15px;
  }

  h4 {
    padding-bottom: 1px;
  }

  .col-movie {
    border: 0 none;
  }

  .col-summary {
    min-height: 0.1vh;
    position: fixed !important;
    bottom: 80px;
    background: #ffffff;
    border-width: 0 0 0 0px;
    z-index: 9998;
    border-radius: 40px 40px 0px 0px;
    padding-top: 16px;

    h4 {
      font-size: 16px;
      font-weight: normal;
    }

    .col-summary-close {
      display: block;
      position: absolute;
      right: 25px;
      top: 17px;
      cursor: pointer;
      background-image: url("/assets/images/close2.svg");
      width: 15px;
      height: 15px;
      background-size: cover;
      margin-right: 1rem;
    }
  }
}

[dir="rtl"] {
  app-page-screen {
    .col-summary-close {
      right: auto;
      left: 25px;
    }
    .col-summary {
      border: 0;
    }
  }
}
