app-user-page {
  display: flex;
  flex-grow: 1;

  #myTab {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .nav-link.active {
    text-decoration: underline;
  }
}
