app-steps {
  .steps-bar {
    background: #000;
    width: 100%;

    &.topTab {
      border-bottom: 6px solid $light-blue;
    }

    .topTabLinks  {
      width: 100%;
      margin: 0 auto;
      background: #000;
      padding: 0;
      list-style: none;
      border-radius: 0;
      display: table;
      table-layout: fixed;
      border-collapse: collapse;

      
    }

    .step {
      display: table-cell;
      padding: 1rem 0;
  
      max-width: calc(25% - 2px) !important;
      padding: 0 10px;

      background: $dark-blue;
      color: $gray;
      border-top: 1px solid $dark-blue;
      border-right: 1px solid $dark-blue;
      border-left: 1px solid $dark-blue;
      border-bottom: none;
      text-decoration: none;
      cursor: default;
      pointer-events: none;
      
      font-size: calc(min(max(1vw, 11px), 22px));

      text-align: center;
      vertical-align: middle;
      text-transform: uppercase;

      height: 48px;
      height: 3.6923076923rem;
      line-height: 1;
      transition: all .2s;
      
      &.current {
        color: #fff;
        background: $light-blue;
        text-decoration: none;
        border-top: 1px solid $light-blue;
        border-right: 1px solid $light-blue;
        border-left: 1px solid $light-blue;
      }
  
      &.completed {
        //border-bottom: 2px solid #C55E7A;
      }
    }
  }
}

@media (max-width: 576px) {
  app-steps {
    .step {
      padding-top: 7px!important;
      vertical-align: top!important;
    }
  }
}
