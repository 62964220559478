app-screen-legend  {
  .legend-container {
    .items {
      .legend-item {
        display: flex;
        flex-direction: column;
        justify-items: center;
      }
      span {
        &:before {
          margin-left: 0 !important;;
        }
        &.couch {
          margin-top:0 !important;
        }
      }
    }
  }
}