app-user-history-order-movie-component {
  .movie-title {
    margin-top: 20px;
    font-size: 1.9em;
    font-weight: 900;
  }
  .cinema-street {
    overflow: hidden;
    font-weight: 700;
    &:before {
      background-image: url("/assets/images/localization.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: left center;
      color: $blue;
      padding: 0 .8rem 0 0;
      width: 1rem;
      height: 1rem;
      content: '';
      margin-right: 0.2rem;
    }
  }
  .items-container {
      .separator {
        &:before {
          content: attr(data-separator);
        }
        &:last-of-type {
          display: none;
        }
      }
  }
}

*[dir="rtl"] {
  app-user-history-order-movie-component {
    .cinema-street {
      &:before {
        margin-left: 0.2rem;
      }
    }
  }
}
