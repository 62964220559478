app-login {
  .show-password-icon {
    color: $light-blue;
  }
}

app-login-providers {
  .providers-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    column-gap: 1rem;
  }

  .provider-logo {
    height: 1.5rem;
  }
}

app-password-input {
  position: relative;
  display: block;
  width: 100%;
}

.absolute-center {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.show-password-icon {
  right: 16px !important;
  top: 11px !important;
}
