app-page-order-summary {
  .order-summary-container {
    .order-summary-qr-container {
      border-width: 0 0px 0 0px;
      border-style: solid;
      border-color: #97999b;
      margin: 0px 0px;
    }

    hr {
      /*display: none;*/
    }

    .booking-wrapper {
      padding-left: 10px;
      padding-right: 10px;

      .card {
        border-radius: 0rem;
        position: relative;

        &.card-movie {
          border-radius: 2.5rem 2.5rem 0rem 0rem;

          .card-body {
            padding: 4rem 0.75rem 0.75rem 0.75rem;
          }
        }

        &.card-order {
          border-radius: 0rem 0rem 2.5rem 2.5rem;
          border-top: 1px solid rgba(0, 0, 0, 0.1);

          .card-body {
            padding: 0.75rem 0.75rem 4rem 0.75rem;
          }
        }
      }
    }

    .head-wrapper {
      padding-right: 15px;
      padding-left: 15px;
      text-align: center;
      color: #4a4a4a;

      h3 {
        color: #4a4a4a;
        font-size: 1.3rem;
      }

      .header-title {
        background: url("/assets/images/booking_ok.svg");
        color: #4a4a4a;
        background-repeat: no-repeat;
        background-position: 50% 0%;
        padding: 45px 0px 0px;

        h3,
        h4 {
          color: #4a4a4a;
        }

        h3 {
          font-size: 1.2rem;
        }

        h4 {
          font-size: 32px;
        }
      }
    }
  }
}
