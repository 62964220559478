app-page-remind {
  .card-header {
    padding: 0;
    img {
      width: 100%;
    }
  }
  .card-body {
    padding: 1.25rem;
  }
}
