app-screening-select-date {
  .screening-select-date {
    .btn {
      font-size: 12px;
      margin: 0 0 0 2px;
      padding: 0.3rem 0.4rem;
    }
    .prev-day-button,.next-day-button {
    }
  }
}
