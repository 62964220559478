app-page-my-profile {
  h3 {
    color: $default-color;
    font-weight: 900;
  }

  h4 {
    padding-top: 20px;
    color: $gray;
  }
}
