app-voucher-selector-component {

  .header {

    background-color: $pink;
    color: white;
    border-top-left-radius: 0.7rem;
    border-bottom-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;

  }

  .increment {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    background: $yellow;
    margin:0px 3px;
    border-radius: 50%;
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    text-align: center;
    font-size: 12px;
    color: #fff;


  }

  .disabled {

    background-color: $gray-300 !important;
    cursor: pointer;

  }

  .ticket {

    background-color: white;
    border-radius: 1.8rem;

  }

  .count {

    display: inline-block;
    width: 2rem;
  }

}
