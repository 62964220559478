app-loading {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;

  .loader-hall {
    -webkit-transition: all linear 0.5s;
    transition: all linear 0.5s;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.3);
  }

  &.transparent .loader-hall {
    background: #edeeef50;
  }

  .indicator {
    color: $dark-blue;
    text-align: center;
    font-size: 32px;

    &-sm {
      font-size: 6px;
    }
  }

  &.ng-hide .loader-hall {
    opacity: 0;
  }
}
