*[app-screening-list-item],
app-screening-list-item {
  .btn {
    margin-right: 0.3rem;
    color: #ffffff;
  }

  button.btn {
    height: 2.4rem !important;
  }

  .movie-title {
    font-size: 1.4rem;
  }

  .pos-absolute {
    position: absolute !important;
  }

  .movie-description {
    font-size: 0.9rem;
    height: auto;
    overflow-y: hidden;
  }

  .movie-rating {
    font-size: 1rem;
  }

  .more {
    display: block;
    color: #004b87;
    cursor: pointer;
    padding-top: 10px;
  }
}
