app-basket-list {
  .list-tickets {
    .seat-column {
      text-transform: none;
      font-weight: normal;
      font-size: 16px;
    }

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      appearance: none;
      outline: 0;
      background: white;
      background-image: none;
      border: 1px solid #666666;
      font-size: 0.89rem;
      font-weight: bold;
    }

    .select {
      position: relative;
      display: block;
      width: 100%;
      height: 2.8em;
      line-height: 2;
      background: white;
      overflow: hidden;
      font-size: 0.8rem;
      font-weight: bold;
    }

    select {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0 0 0 0.5em;
      color: black;
      cursor: pointer;
    }

    select::-ms-expand {
      display: none;
    }

    .select::after {
      -webkit-transition: 0.25s all ease;
      -o-transition: 0.25s all ease;
      transition: 0.25s all ease;

      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      padding: 0.3rem 2em;
      background: white;
      pointer-events: none;
      color: #666666;
      border-top: 1px solid gray;
      border-bottom: 1px solid gray;
      border-right: 1px solid gray;
      font-size: 0.6rem;
      background-image: url("/assets/images/select-arrow-sprite.png");
      background-repeat: no-repeat;
      background-size: 120px 60px;
      background-position: -30px -0px;
    }
  }

  .book-tickets {
    &-top {
      color: $white;

      &::after,
      &::before {
        content: " ";
        display: table;
      }

      &::after {
        clear: both;
      }

      &-label {
        float: left;
      }

      &-field {
        float: right;
      }
    }

    &-foot {
      font-weight: bold;
      padding: 20px 0;
      padding: 1.5384615385rem 0;
      line-height: 1.5em;
      color: #000;
    }

    .book-ticket {
      border-bottom: 1px solid #bfbfbf;
      padding: 20px 0;
      padding: 1.5384615385rem 0;
      display: table;
      width: 100%;

      &::after,
      &::before {
        content: " ";
        display: table;
      }

      &::after {
        clear: both;
      }

      &-label {
        display: table-cell;
        vertical-align: top;
        color: #000;
        padding-right: 10px;
        padding-right: 0.7692307692rem;
      }

      &-price {
        display: table-cell;
        vertical-align: top;
        font-weight: bold;
        width: 70px;
        width: 5.3846153846rem;
        color: #000;
        text-align: right;
        padding-right: 20px;
        padding-right: 1.5384615385rem;
      }

      &-type {
        display: table-cell;
        vertical-align: top;
        text-align: right;
        width: 100px;
        width: 10rem;
      }
    }

    [class*="select-"] {
      background: #fff;
    }

    [class*="select-"] {
      position: relative;
      display: inline-block;
      width: 100%;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
  }

  .book-link-back {
    color: #666;
    text-decoration: none;
    text-transform: uppercase;
    padding: 5px 13px;
    padding: 0.3846153846rem 1rem;
    font-weight: bold;
    float: right;
    line-height: 23px;
    line-height: 1.7692307692rem;
  }

  @media screen and (min-width: 47.938em) and (max-width: 59.9375em), screen and (min-width: 60em) {
    .book-tickets-submit {
      float: right;
      width: auto;
    }
  }
}
