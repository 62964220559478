app-user-area-navigation-component {

  .menu-container {


    display: flex;;
    flex-direction: row;;
    flex-wrap: wrap;
    justify-content: right;

    .menu-item {

      padding: 0 0.4rem;

      .btn-navtab-selected {
        border-radius: 0.9rem .4rem .4rem .4rem;
        background-color: #82378d;
        padding: 0.3rem 2rem;
        border-width: 0;
        border-top: none currentcolor;
        font-size: 1.1rem;
        color: white;
        margin: 10px auto;
        font-weight: bold;
      }

      .btn-navtab {

        border-radius: 0.9rem .4rem .4rem .4rem;
        background-color: $gray-600;
        padding: 0.3rem 2rem;
        border-width: 0;
        border-top: none $gray-600;
        font-size: 1.1rem;
        font-weight: normal !important;
        color: white;
        margin: 10px auto;

      }

      .btn-outline-navtab {
        background-color: $gray-600 !important;
      }


    }

    .selected {

      opacity: 0.7;

    }

    drag-scroll {

      .drag-scroll-content {

        overflow: scroll;
        overflow-x: hidden;
        overflow-y: hidden;
      }

    }

  }


}
