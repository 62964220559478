app-cart-popup-cart-ticket-item-component {

  .container-row {

    align-items: normal !important;

  }

  .cart-container {

    max-height: 56vh;
    overflow-y: auto;
    overflow-x: hidden;


    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;

    }

    &::-webkit-scrollbar-thumb {
      background-color: $pink;
      border-radius: 30px;
    }

  }

  .movie-poster {

    width: 6rem;
    height: 9rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;

  }

  .ticket-item {

    font-size: 0.9rem;

    .unit-price {

      font-size: .75rem;

    }

  }

  .movie-title {

    font-weight: bold;


  }

  .col-drop {
    display: flex;
    align-items: center;
    color: $pink;

    .remove-button {

      &:hover {

        opacity: 0.8;
        cursor: pointer;

      }

    }
  }

  .strike {

    padding-top: 1rem;
    border-bottom: 1px solid #9b9b9b;
    width: 100%;
    margin-bottom: .5rem;

  }

}
