app-user-login {
  .next-info {
    margin: 0px 0px;
    text-align: center;
    padding: 20px 0px;
    margin: 0 -1.25rem -1.25rem;
  }

  .card-footer {
    padding: 0;
  }

  .card-body {
    padding: 0;
  }

  .card-header {
    padding: 20px 0px;
  }

  .card {
    padding: 0 20px;
  }

  h3 {
    color: #4a4a4a;
    font-weight: bold;
    font-size: 16px;
  }

  .btn-primary {
    border-radius: 1.5rem 0.6rem 0.6rem 0.6rem;
    background: linear-gradient(to right, #05c3de, #004b87);
    padding: 0.3rem 2rem;
    border: 0;
    border-top: 0;
    font-size: 1.5rem;
  }
}
