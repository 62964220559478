app-page-message {
  display: flex;
  flex-flow: column;
  height: 100%;
  flex: 1 0 auto;

  .card.primary {
    border: 2px solid $light-blue !important;

    .card-header {
      background-color: $background-header;
      .title {
        font-weight: bold;
        color: $white;
      }
      h3 {
        color: #ffffff;
      }
    }
    .card-footer {
      background-color: $background-header;
    }
  }

  .container {
    flex: 1 0 auto;
  }
}
