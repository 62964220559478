app-page-screen {
  display: flex;
  flex-flow: column;
  height: 100%;
  flex: 1 0 auto;

  .container {
    flex: 1 0 auto;
    border-bottom: 6px solid $dark-blue;
  }

  h4 {
    padding-bottom: 2rem;
  }
  .space {
    padding-top: 72px;
  }
  .screen-header {
    text-align: center;
    color: $black;
    background: #5a5a5a;
    background: -moz-linear-gradient(top, #5a5a5a 0%, $yellow 100%);
    background: -webkit-linear-gradient(top, #5a5a5a 0%, $yellow 100%);
    background: -o-linear-gradient(top, #5a5a5a 0%, $yellow 100%);
    background: -ms-linear-gradient(top, #5a5a5a 0%, $yellow 100%);
    background: linear-gradient(to bottom, #5a5a5a 0%, $yellow 100%);
    text-transform: uppercase;
    padding: 9px;
    margin: 0 auto 20px;
    height: 3rem;
    font-size: calc(min(max(1vw, 12px), 27px));
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    &:before {
      content: "";
      line-height: 0;
      font-size: 0;
      width: 0;
      height: 0;
      border-top: 26px solid transparent;
      border-bottom: 26px solid $dark-blue;
      border-left: 26px solid $dark-blue;
      border-right: 26px solid transparent;
      position: absolute;
      top: 1px;
      left: 0;
    }
    &:after {
      content: "";
      line-height: 0;
      font-size: 0;
      width: 0;
      height: 0;
      border-top: 26px solid transparent;
      border-bottom: 26px solid $dark-blue;
      border-left: 26px solid transparent;
      border-right: 26px solid $dark-blue;
      position: absolute;
      top: 1px;
      right: 0;
    }
  }
  .col-summary {
    transform: rotate(-360deg);
    .col-summary-close {
      display: none;
    }
  }
  .screen-swapping-popup {
    text-align: center;
    display: flex;
    flex-direction: column;
    min-height: 20rem;
    .popup-close-button {
      margin-top: auto;
    }
  }
}

pinch-zoom {
  .pz-zoom-button.pz-zoom-control-position-bottom {
    left: 90% !important;
  }
}

[dir="ltr"] {
  app-page-screen {
    .strike-desc {
      display: block;
      overflow: hidden;
      padding-left: 30px;
      font-size: 0.9rem;
      > span {
        position: relative;
        display: inline-block;
        &:before {
          content: "";
          position: absolute;
          top: 50%;
          width: 99px;
          height: 1px;
          background: $light-blue;
        }
        &:before {
          right: 100%;
          margin-right: 15px;
        }
      }
    }
  }
}
