app-voucher-selector-component {

  .increment {

    background: #05c3de !important;


  }



}
