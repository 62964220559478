app-page-catering {
  .col-summary {
    min-height: 0.1vh;
    position: fixed !important;
    bottom: 80px;
    background: #ffffff;
    border-width: 0 0 0 0px;
    z-index: 9998;
    border-radius: 40px 40px 0px 0px;
    padding-top: 16px;

    h4 {
      font-size: 16px;
      font-weight: normal;
    }

    .col-summary-close {
      display: block;
      position: absolute;
      right: 25px;
      top: 17px;
      cursor: pointer;
      background-image: url("/assets/images/close2.svg");
      width: 15px;
      height: 15px;
      background-size: cover;
      margin-right: 1rem;
    }
  }
}

[dir="rtl"] {
  app-page-catering {
    .col-summary-close {
      right: auto;
      left: 25px;
    }
    .col-summary {
      border-width: 0 1px 0 0 !important;
    }
  }
}
