app-payment-credit-card {
  .credit-card-container {
    padding: 2rem;
    margin: 2rem 0;
    background-color: #e6f9fb;
    border-radius: 1rem;

    .form-control {
      padding: 2rem 3rem;
      border: 0;
    }
  }

  .wpwl-form-card {
    float: left;
    background-color: #e6f9fc;
    box-shadow: none;
    border: none;
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  .wpwl-group-brand {
    display: none;
  }

  /* Checkbox */
  .cardSave {
    display: inline-block;
    font-weight: 600;
    font-size: 14px;
  }

  .cardLabel {
    position: relative;
    padding-left: 28px;

    &::before {
      position: absolute;
      display: inline-block;
      content: "";
      left: 0;
      top: 3px;
      height: 16px;
      width: 16px;
      border: 1px solid;
    }

    &::after {
      position: absolute;
      display: inline-block;
      content: "";
      height: 6px;
      width: 16px;
      border-left: 2px solid;
      border-bottom: 2px solid;
      transform: rotate(-35deg);
      left: 6px;
      top: 5px;
      color: #004b87;
    }
  }

  .cardInput {
    display: none;

    & + .cardLabel::after {
      content: none;
    }

    &:checked + .cardLabel::after {
      content: "";
    }

    &:focus + .cardLabel::after {
      outline: rgb(59, 153, 252) auto 5px;
    }
  }

  @media (min-width: 480px) {
    .wpwl-group-cardNumber,
    .wpwl-group-expiry,
    .wpwl-group-cvv,
    .wpwl-group-cardHolder {
      width: 100%;
    }

    .wpwl-group-cardNumber,
    .wpwl-group-cardHolder {
      padding-right: 0;
    }
  }

  @media (min-width: 1024px) {
    .wpwl-group-cardNumber {
      width: 100%;
      padding: 0 15px 0 0;
    }

    .wpwl-group-expiry {
      width: 50%;
    }

    .wpwl-group-cvv {
      width: 50%;
      padding: 0 15px 0 0;
    }

    .wpwl-group-cardHolder {
      width: 100%;
      padding: 0 15px 0 0;
    }
  }

  .wpwl-wrapper-submit {
    padding: 20px 20px 0;
  }

  .wpwl-button-pay {
    background: linear-gradient(to right, #05c3de, #004b87);
    border: none;
    font-size: 16px;
    padding: 5px 35px;
    border-radius: 5px;
    border-top-left-radius: 15px;
    font-weight: bold;
    float: left;
  }

  /*Stylowanie inputów oraz divów oplatających je*/
  .wpwl-control {
    border: none;
  }

  .wpwl-control-cardNumber,
  .wpwl-control-cardHolder,
  .wpwl-control-cvv,
  .wpwl-control-expiry {
    padding: 0.7rem;
    height: 48px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 0;
    flex: 1 1;
    min-width: 1px;
  }

  .wpwl-control-cardNumber,
  .wpwl-control-cvv {
    font-family: $font-family-base;
  }

  @media (max-width: 480px) {
    .wpwl-control-cardNumber {
      width: 75%;
      font-size: 0.9em;
    }
  }

  .wpwl-control-cvv {
    width: 60%;
  }

  .wpwl-wrapper-cardNumber,
  .wpwl-wrapper-cardHolder,
  .wpwl-wrapper-cvv,
  .wpwl-wrapper-expiry {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 10px;
  }

  /* IKONY */
  .wpwl-icons {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 30px;
    height: 30px;
    display: inline-block;
    margin: 5px 20px 5px 5px;
    min-width: 30px;
  }

  .wpwl-cardNumber-icon {
    background-image: url("/assets/images/card.svg");
  }

  .wpwl-cardHolder-icon {
    background-image: url("/assets/images/user.svg");
  }

  .wpwl-expiry-icon {
    background-image: url("/assets/images/calendar.svg");
  }

  .wpwl-cvv-icon {
    @media (min-width: 1024px) {
      width: 20%;
    }

    background-image: url("/assets/images/lock.svg");
  }

  .wpwl-hint-cardNumberError,
  .wpwl-hint-expiryMonthError,
  .wpwl-hint-cardHolderError,
  .wpwl-hint-cvvError {
    width: 100%;
    padding-left: 55px;
    font-size: 0.75em;
  }

  /*BRAND w Inputach*/
  @media (min-width: 1024px) {
    .wpwl-brands {
      display: block;
      background-image: url("/assets/images/brand.png");
      background-repeat: no-repeat;
      background-size: contain;
      width: 100%;
      background-position-x: 250px;
    }

    .wpwl-brand-VISA {
      background-position-y: -5608px;
    }

    .wpwl-brand-AIRPLUS {
      background-position-y: -58px;
      background-position-y: 100px;
    }

    .wpwl-brand-MAESTRO {
      background-position-y: -2859px;
      background-position-y: 100px;
    }

    .wpwl-brand-AMEX {
      background-position-y: -260px;
    }

    .wpwl-brand-CABAL {
      background-position-y: -756px;
      background-position-y: 100px;
    }

    .wpwl-brand-MASTER {
      background-position-y: -2966px;
    }

    .wpwl-brand-DANKORT {
      background-position-y: -1202px;
      background-position-y: 100px;
    }

    .wpwl-brand-DINERS {
      background-position-y: -1455px;
      background-position-y: 100px;
    }

    .wpwl-brand-BELK_GIFT_CARD {
      background-position-y: -529px;
      background-position-y: 100px;
    }

    .wpwl-brand-JCB {
      background-position-y: -2448px;
      background-position-y: 100px;
    }

    .wpwl-brand-MADA {
      background-position-y: -2830px;
    }

    .wpwl-control-cardNumber.wpwl-brand-MASTER {
      height: 60px;
    }
  }
}
