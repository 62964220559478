app-screen-movie {
  .cinema-label {
    font-size: .8rem;
  }

  .hrline-block {
    width: 100%;;
  }

  .movie-container {
    display: flex;
    flex-direction: column;
  }

  .screen-movie-container {
    .poster {
      padding-right: 0 !important;
      .img-fluid {
        height:  unset !important;
      }
    }

    .movie-title {
      font-size: .75rem;
      line-height: .85rem;
    }

    .movie-details-container {
      font-size: .7rem;
    }

    .second-movie-details-container {
      margin-top: auto;
    }
  }
}
