.modal-header {
  background: $primary-color;
}

.modal-footer {
  background: $primary-color;
}

.modal {
  .close {
    content: "";
    position: absolute;
    right: 30px;
    top: 30px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    background-size: cover;
    opacity: 0.8;
  }
  .close:not(:disabled):not(.disabled):hover {
    opacity: 1;
  }
}
