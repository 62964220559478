app-user-preferences-favorite-cinema-component {

  .favourite-cinema {

    display: flex;
    flex-direction: row;
    align-items: center;

    .city {

      color: $blue;

    }

    .box {

      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: white;
      width: 240px;
      height: 40px;
      border-radius: 15px;
      text-align: center;
      margin-left: 20px;

      .icon {

        padding-left: 15px;
        padding-right: 15px;

        &.checked {
          &::before {
            background-image: url("/asset/images/heart_on.svg") !important;
            content: '';
          }
        }

        &::before {
          background-image: url("/assets/images/heart_off.svg");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: left center;
          color: $blue;
          padding: 0 .8rem 0 0;
          width: 1rem;
          height: 1rem;
          content: '';
          margin-right: 5px;
        }

        &:hover{
          opacity: 0.8;
          cursor: pointer;
        }

      }




      & .name {

        color: $gray-600;

      }

    }

  }

}

*[dir="rtl"]{

  app-user-preferences-favorite-cinema-component {

    .favourite-cinema {

      .city {

        padding-left: 0.4rem;
      }
    }

  }
}
