
app-screen-ticket-count-selector {
  .card {
    background-color: $default-color;
    height:95px;
    vertical-align: middle;
    color:#212529;
    padding-top:25px;
  }
  strong {
    font-size:30px;
  }
  .btn {
    display: inline-block;
    background: red;
    border-radius: 50%;
    width:40px;
    height:40px;
    vertical-align: middle;
    line-height: 40px;
    padding: 0;
    background-color: #ffffff;
    color:$default-color;
    margin:0;
    font-weight: bold;
    font-size: 19px;
  }

  .count {
    font-size:20px;
    line-height: 40px;
    vertical-align: middle;
    margin: 0px 15px;
    min-width: 40px;
    display: inline-block;
  }
}
