app-message {
  display: block;

  .message-container {
    padding: 1rem 0;

    .alert {
      border-radius: 1rem;
      font-weight: bold;
    }

    .alert-danger {
      border: 2px solid #ff0000;
      color: #000000 !important;
    }
  }
}
