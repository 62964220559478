app-layout {
  display: flex;
  flex-flow: column;
  min-height: 100vh;

  .content {
    display: flex;
    flex-flow: column;
    height: 100%;
    flex: 1 0 auto;
  }

  .main-container {
    app-loading {
      position: relative;
      display: contents;
    }
  }

  app-main {
    display: flex;
    flex-flow: column;
    height: 100%;
    flex: 1 0 auto;
  }

  .smooth-in {
    transition: opacity 0.1s ease;
    opacity: 0;
    &-completed {
      transition: opacity 0.4s ease;
      opacity: 1;
    }
  }

  .grid-col4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 2rem;
    padding: 0 3rem 0 3rem;

    @media (max-width: 576px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 576px) and (max-width: 766px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 768px) and (max-width: 990px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 992px) and (max-width: 1198px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 1200px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
