app-user-preferences-favorite-cinema-component {

  .favourite-cinema {

    flex-direction: column;
    align-items: center;

    .box {

      margin-left: 0;

    }

  }

}
