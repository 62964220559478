app-dropdown .dropdown-wrapper {
  display: block;
}

app-screenings-dropdown .dropdown-wrapper {
  display: flex;
}

app-dropdown,
app-screenings-dropdown {
  // DROPDOWN STYLES
  .dropdown-wrapper {
    border: 1px solid white;
    position: relative;

    justify-content: center;
    align-items: center;
    perspective: 1000px;

    &.focus {
      border: 1px solid $light-blue;
    }

    .box {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
    }

    // SELECTED
    .dropdown {
      height: 39px;
      position: relative;
      padding: 0 0 0 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      overflow: hidden;
      font-size: 1rem;
      padding: 0 2.25rem 0 1rem;

      &-selectable {
        background: url("/assets/images/dropdown_blue.svg") no-repeat right center;
        background-size: 12px;
        background-position: right 10px center;

        &:hover {
          cursor: pointer;
          .dropdown_menu li,
          .dropdown_menu--animated {
            cursor: pointer;
          }
        }
      }

      &.dropdown-voucher {
        background: url("/assets/images/cancel_black.svg") no-repeat right 1rem center;

        .dropdown-price {
          color: $green;
        }
      }

      @media (max-width: 576px) {
        &.dropdown-voucher {
          background-position: right;
        }
      }

      span {
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .dropdown-options {
      display: none;
      position: absolute;
      top: 100%;

      perspective: 1000px;

      padding: 8px 6px 9px 5px !important;
      max-height: 261px;
      overflow-y: auto;
      z-index: 9999;
      list-style-type: none;
      scrollbar-color: $light-blue transparent;
      scrollbar-width: thin;

      border-radius: 0;
      background-color: $dark-blue;
      border: none;
      box-shadow: 0 0px 2px 0 white;
      text-align: right;
      min-width: 70px;

      flex-direction: column;
      width: 100%;
      li {
        padding: 10px;
        position: relative;
        line-height: 1;
        font-size: 0.9rem;
        white-space: nowrap;
        color: $white;

        &:last-child {
          border-bottom: none;
        }

        &:hover {
          background-color: $light-blue;
          border-radius: 0;
          border-bottom-color: transparent;
        }

        &:focus {
          background-color: $yellow;
          border-radius: 0;
        }

        &.active {
          background-color: $yellow;
          border-radius: 0;
          border-bottom-color: transparent;
        }

        &.active {
          font-weight: 600;
        }
      }

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $light-blue;
        border-radius: 0;
      }
    }

    .dropdown-thin {
      width: 85px;
    }

    &:hover {
      .dropdown_menu li,
      .dropdown_menu--animated {
        cursor: pointer;
        //display: block;
      }
    }

    &.is-open {
      z-index: 100;
      .dropdown-options {
        display: flex;
        li {
          display: block;
        }
      }
    }

    .dropdown_menu li {
      cursor: pointer;
      display: none;
      opacity: 0;
    }
    .dropdown_menu--animated {
      display: none;
    }
    .dropdown_menu--animated li {
      display: block;
      opacity: 1;
    }
    .dropdown_menu-1 {
      animation: growDown 300ms ease-in-out forwards;
      transform-origin: top center;
    }
    .dropdown_menu-2 {
      animation: rotateMenu 400ms ease-in-out forwards;
      transform-origin: top center;
    }
    .dropdown_menu-3 {
      animation: downOut 300ms ease-in-out forwards;
      transform-origin: center center;
    }
    .dropdown_menu-4 {
      animation: growOut 300ms ease-in-out forwards;
      transform-origin: top center;
    }
    .dropdown_menu-5 {
      animation: rotateY 300ms ease-in-out forwards;
      transform-origin: top center;
    }
  }

  @-moz-keyframes growDown {
    0% {
      transform: scaleY(0);
    }
    80% {
      transform: scaleY(1.1);
    }
    100% {
      transform: scaleY(1);
    }
  }
  @-webkit-keyframes growDown {
    0% {
      transform: scaleY(0);
    }
    80% {
      transform: scaleY(1.1);
    }
    100% {
      transform: scaleY(1);
    }
  }
  @-o-keyframes growDown {
    0% {
      transform: scaleY(0);
    }
    80% {
      transform: scaleY(1.1);
    }
    100% {
      transform: scaleY(1);
    }
  }
  @keyframes growDown {
    0% {
      transform: scaleY(0);
    }
    80% {
      transform: scaleY(1.1);
    }
    100% {
      transform: scaleY(1);
    }
  }
  @-moz-keyframes rotateMenu {
    0% {
      transform: rotateX(-90deg);
    }
    70% {
      transform: rotateX(20deg);
    }
    100% {
      transform: rotateX(0deg);
    }
  }
  @-webkit-keyframes rotateMenu {
    0% {
      transform: rotateX(-90deg);
    }
    70% {
      transform: rotateX(20deg);
    }
    100% {
      transform: rotateX(0deg);
    }
  }
  @-o-keyframes rotateMenu {
    0% {
      transform: rotateX(-90deg);
    }
    70% {
      transform: rotateX(20deg);
    }
    100% {
      transform: rotateX(0deg);
    }
  }
  @keyframes rotateMenu {
    0% {
      transform: rotateX(-90deg);
    }
    70% {
      transform: rotateX(20deg);
    }
    100% {
      transform: rotateX(0deg);
    }
  }
  @-moz-keyframes downOut {
    0% {
      transform: translateZ(200px) transLateY(40px);
    }
    80% {
      transform: translateZ(-10px) transLateY(0px);
    }
    100% {
      transform: translateZ(0px) transLateY(0px);
    }
  }
  @-webkit-keyframes downOut {
    0% {
      transform: translateZ(200px) transLateY(40px);
    }
    80% {
      transform: translateZ(-10px) transLateY(0px);
    }
    100% {
      transform: translateZ(0px) transLateY(0px);
    }
  }
  @-o-keyframes downOut {
    0% {
      transform: translateZ(200px) transLateY(40px);
    }
    80% {
      transform: translateZ(-10px) transLateY(0px);
    }
    100% {
      transform: translateZ(0px) transLateY(0px);
    }
  }
  @keyframes downOut {
    0% {
      transform: translateZ(200px) transLateY(40px);
    }
    80% {
      transform: translateZ(-10px) transLateY(0px);
    }
    100% {
      transform: translateZ(0px) transLateY(0px);
    }
  }
  @-moz-keyframes growOut {
    0% {
      transform: scale(0);
    }
    80% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  @-webkit-keyframes growOut {
    0% {
      transform: scale(0);
    }
    80% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  @-o-keyframes growOut {
    0% {
      transform: scale(0);
    }
    80% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes growOut {
    0% {
      transform: scale(0);
    }
    80% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  @-moz-keyframes rotateY {
    0% {
      transform: rotateY(90deg);
    }
    80% {
      transform: rotateY(-10deg);
    }
    100% {
      transform: rotateY(0);
    }
  }
  @-webkit-keyframes rotateY {
    0% {
      transform: rotateY(90deg);
    }
    80% {
      transform: rotateY(-10deg);
    }
    100% {
      transform: rotateY(0);
    }
  }
  @-o-keyframes rotateY {
    0% {
      transform: rotateY(90deg);
    }
    80% {
      transform: rotateY(-10deg);
    }
    100% {
      transform: rotateY(0);
    }
  }
  @keyframes rotateY {
    0% {
      transform: rotateY(90deg);
    }
    80% {
      transform: rotateY(-10deg);
    }
    100% {
      transform: rotateY(0);
    }
  }
  @-moz-keyframes slideDown {
    0% {
      opacity: 0;
      transform: translateY(-60px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @-webkit-keyframes slideDown {
    0% {
      opacity: 0;
      transform: translateY(-60px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @-o-keyframes slideDown {
    0% {
      opacity: 0;
      transform: translateY(-60px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes slideDown {
    0% {
      opacity: 0;
      transform: translateY(-60px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @-moz-keyframes rotateX {
    0% {
      opacity: 0;
      transform: rotateX(-90deg);
    }
    50% {
      transform: rotateX(-20deg);
    }
    100% {
      opacity: 1;
      transform: rotateX(0deg);
    }
  }
  @-webkit-keyframes rotateX {
    0% {
      opacity: 0;
      transform: rotateX(-90deg);
    }
    50% {
      transform: rotateX(-20deg);
    }
    100% {
      opacity: 1;
      transform: rotateX(0deg);
    }
  }
  @-o-keyframes rotateX {
    0% {
      opacity: 0;
      transform: rotateX(-90deg);
    }
    50% {
      transform: rotateX(-20deg);
    }
    100% {
      opacity: 1;
      transform: rotateX(0deg);
    }
  }
  @keyframes rotateX {
    0% {
      opacity: 0;
      transform: rotateX(-90deg);
    }
    50% {
      transform: rotateX(-20deg);
    }
    100% {
      opacity: 1;
      transform: rotateX(0deg);
    }
  }
  @-moz-keyframes rotateZ {
    0% {
      opacity: 0;
      transform: translateZ(290px);
    }
    80% {
      transform: translateZ(10px);
    }
    100% {
      opacity: 1;
      transform: translateZ(0);
    }
  }
  @-webkit-keyframes rotateZ {
    0% {
      opacity: 0;
      transform: translateZ(290px);
    }
    80% {
      transform: translateZ(10px);
    }
    100% {
      opacity: 1;
      transform: translateZ(0);
    }
  }
  @-o-keyframes rotateZ {
    0% {
      opacity: 0;
      transform: translateZ(290px);
    }
    80% {
      transform: translateZ(10px);
    }
    100% {
      opacity: 1;
      transform: translateZ(0);
    }
  }
  @keyframes rotateZ {
    0% {
      opacity: 0;
      transform: translateZ(290px);
    }
    80% {
      transform: translateZ(10px);
    }
    100% {
      opacity: 1;
      transform: translateZ(0);
    }
  }
  @-moz-keyframes scaleZ {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    80% {
      transform: scale(1.07);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  @-webkit-keyframes scaleZ {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    80% {
      transform: scale(1.07);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  @-o-keyframes scaleZ {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    80% {
      transform: scale(1.07);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  @keyframes scaleZ {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    80% {
      transform: scale(1.07);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  @-moz-keyframes scaleZ {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    80% {
      transform: scale(1.07);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  @-webkit-keyframes scaleZ {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    80% {
      transform: scale(1.07);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  @-o-keyframes scaleZ {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    80% {
      transform: scale(1.07);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  @keyframes scaleZ {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    80% {
      transform: scale(1.07);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  @-moz-keyframes translateX {
    0% {
      opacity: 0;
      transform: translateX(60px);
    }
    80% {
      transform: translateX(-5px);
    }
    100% {
      opacity: 1;
      transform: translateX(0px);
    }
  }
  @-webkit-keyframes translateX {
    0% {
      opacity: 0;
      transform: translateX(60px);
    }
    80% {
      transform: translateX(-5px);
    }
    100% {
      opacity: 1;
      transform: translateX(0px);
    }
  }
  @-o-keyframes translateX {
    0% {
      opacity: 0;
      transform: translateX(60px);
    }
    80% {
      transform: translateX(-5px);
    }
    100% {
      opacity: 1;
      transform: translateX(0px);
    }
  }
  @keyframes translateX {
    0% {
      opacity: 0;
      transform: translateX(60px);
    }
    80% {
      transform: translateX(-5px);
    }
    100% {
      opacity: 1;
      transform: translateX(0px);
    }
  }
}

app-dropdown {
  .dropdown-wrapper {
    .dropdown-options {
      // Temporary while the Chrome bug won't be fixed
      li > span.text-truncate {
        overflow: initial;
        text-overflow: clip;
      }
    }
  }
}
