app-user-login {

  .card-header{

    padding: 20px 30px;
  }

  .card-footer {

    padding: 0px 30px;
  }

}
