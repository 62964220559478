app-account-items-vouchers,
app-account-items-cards {
  display: flex;
  flex-direction: column;
}

app-account-items-vouchers-list,
app-account-items-cards-list {
  > div {
    gap: 0.5rem;
  }
}
