app-user-navigation {
  span {
    font-size:20px;
    display: inline-block;
    cursor: pointer;

    &::before {
      background-image: url("/assets/images/back_4a4a4a.svg");
      background-repeat: no-repeat;
      background-position: left 18px;
      padding: 0 0rem 0 0;
      content: '';
      width: 1rem;
      height: 53px;
      display: inline-block;
      vertical-align: middle;
    }
  }
}

*[dir="rtl"] {

  app-user-navigation {
    span {

      &::before {
        -moz-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
        background-position: left 18px;
      }
    }
  }

}
