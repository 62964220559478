@mixin size-list {
  @for $i from 1 through $seats-columns {
    &.size-#{$i} {
      width: ((($seats-column-size + (2 * $seats-column-margin)) * $i) + (($seats-column-legend-size + (2 * $seats-column-margin)) * 2)) + px;
      margin: 0 auto;
    }
  }
}

@mixin size-placeholder-list {
  @for $i from 1 through $seats-columns {
    &.size-placeholder-#{$i} {
      $w: ($seats-column-size * $i + ($seats-column-margin * 2) * ($i - 1)) + px;
      width: $w;
      @if $i>1 {
        min-width: $w !important;
      } @else {
        min-width: $w;
      }
    }
  }
}
