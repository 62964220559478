app-user-history-order-qr-component {

  .order-history-qr {

    .zoom {

      &:before {
        border: none !important;;
      }
    }

  }

}
