app-page-order-summary {
  .order-summary-container {
    hr {
      display: none;
    }

    .order-summary-qr-container {
      border-width: 0;
    }
  }
}
