app-order-tickets-summary-ga {

  display: block;

  .tickets-ga-summary-container {

    padding-top: 2rem;
    display: flex;
    flex-direction: row;

    .icon {

      padding-top: 3.2em;
    }


  }

  .tickets-ga-summary-container:before {
    width: 2rem;
    background-image: url("/assets/images/tickets.svg");
    background-position: center left;
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    padding: 0 0 0 0;
    margin: 0px 7px 0px 0px;
  }

}
