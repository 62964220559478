app-page-personal-and-payment {
  display: block;
  padding-bottom: 7rem;

  h4 {
    padding-bottom: 2rem;
  }

  .col-movie {
    padding-right: 1rem;
    border-width: 0 1px 0 0;
    border-style: solid;
    border-image: linear-gradient(to top, #e0004d, #b32675 33%, #004b87 68%, #0280ae 86%, #05c3de);
    transform: rotate(-360deg);
    border-image-slice: 1;
  }

  .col-main {
    padding: 0 3rem;

    .panel-group {
      .card {
        border-radius: 2rem;
        margin-bottom: 2rem;

        .card-header {
          border-radius: 2rem;

          .btn-link {
            font-size: 1.2rem;
            color: $body-color;

            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  .col-summary {
    padding-right: 1rem;
    border-width: 0 0 0 1px;
    border-style: solid;
    border-image: linear-gradient(to top, #e0004d, #b32675 33%, #004b87 68%, #0280ae 86%, #05c3de);
    transform: rotate(-360deg);
    border-image-slice: 1;
  }
}

*[dir="rtl"] app-page-personal-and-payment {
  .col-movie {
    border-width: 0 0 0 1px;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .col-summary {
    padding-right: 1rem;
    padding-left: 1rem;
    border-width: 0 1px 0 0;
  }
}
