app-book-movie-list {
  .book {
    &-movie {
      color: $white;

      &-head {
        margin: 0 0 10px;
      }
      &-experience {
        padding: 10px;
        margin: 10px 0;
      }
      &-image {
        position: relative;
        height: 100px;
        img {
          display: block;
          margin: 0 auto;
          height: 100% !important;
        }
      }
      &-info {
        padding: 20px 10px;
      }
      &-extend {
        padding-left: 1rem;
      }
      &-extend {
        &-label {
          font-size: 15px;
        }
        &-field {
          color: #666;
        }
      }
      &-title {
        text-align: left;
        text-transform: uppercase;
        font-size: 20px;
      }
      &-list {
        &-row {
          padding: 10px 0 0;
          margin: 10px 0 0;
          &:first-child {
            margin-top: 0;
          }
          &:before,
          &:after {
            content: " ";
            display: table;
          }
          &:after {
            clear: both;
          }
        }
        &-label {
          float: left;
          width: 40%;
          text-transform: uppercase;
          font-size: 16px;
        }
        &-field {
          float: right;
          text-align: right;
          width: 60%;
          word-wrap: break-word;
        }
      }

      &-cost {
        padding: 10px 10px 20px;
        background: $light-blue;
        color: $white;
        display: none;
        &-row {
          margin: 10px 0 0;
          &:before,
          &:after {
            content: " ";
            display: table;
          }
          &:after {
            clear: both;
          }
        }
        &-label {
          float: left;
          width: 60%;
          text-transform: uppercase;
          font-size: 16px;
        }
        &-field {
          float: right;
          text-align: right;
          width: 40%;
        }
      }
    }
    &-confirm-image {
      display: none;
    }
  }
}
