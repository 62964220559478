app-screening-select-date {
  .screening-select-date {
    text-align: center;
    padding: 20px 0px 0px;
    .prev-day-button {

    }

    .next-day-button {

    }

    .btn {
      font-size:  $font-size-base*0.95;
      min-width: 39px;
      padding: 0.3rem 0.6rem;
      margin: 0 0 0 2px;
      display: inline-block;
      &:first-child {
        margin-left: 0;
      }

      span {
        display: block;

        &.day-name {

        }
        &.day-number {
          font-weight: bold;
        }
        &.month-name {
          text-transform: uppercase;
        }
      }
    }
  }
}
