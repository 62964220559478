app-header {
  .container {
    background-color: $dark-blue;
    position: relative;
    width: 100%;

    div.borderbottom {
      border-bottom: 6px solid $light-blue;
    }

    .brand {
      padding: 1rem 0;
    }
  }
}

@media (max-width: 576px) {
  app-header {
    .container {
      .brand {
        width: 50%;
      }
    }
  }
}

.logocontainer {
  @media (max-width: 768px) {
    img {
      height: 35px !important;
    }
  }
}
