.icon {
  font-family: 'Font Awesome 5 Free';
  &.free {
      background-color: $background-icon-free;
      content: ' ';
  }
  &.normal {
    background-color:  pink;
  }
  &.mine {
    background-color: $background-icon-mine!important;
    content: fa-content($fa-var-user);
  }
  &.occupied {
    background-color:  $background-icon-occupied !important;
    content: fa-content($fa-var-user);
  }


}
