app-voucher-card-input,
app-voucher-input,
app-card-input {
  .btn-disabled {
    cursor: default;
    opacity: 0.5;
  }

  label {
    font-size: 0.7rem;
    font-weight: bold;
    margin-left: 3rem;
    padding-bottom: 0.4rem;
  }

  .errors {
    .error {
      color: red;
      font-size: 0.9rem;
      margin-top: 1rem;
    }
  }

  .input-label {
    text-transform: uppercase;
  }
}
