app-user-register {

  .card-footer {

    padding: 0px 30px;
  }

  .card-header {

    padding: 20px 30px;
  }

  .card-body {

    padding: 0px 30px;
  }

}
