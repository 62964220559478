app-infobox {
  h3 {
    color: #4a4a4a;
    padding-bottom: 20px;
  }

  .card-header {
    padding: 0px 0px;
  }

  .card-body {
    padding: 0px 120px 20px;
  }

  .card-footer {
    padding: 20px 120px;
    background: #dff7fb;
  }

  img {
    width: 100%;
  }
}
