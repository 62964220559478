app-page-remind {
  .card-header {
    padding: 0;
    img {
      width: 100%;
    }
  }
  .card-body {
    padding: 0px 120px;
  }


}
