@import "../icon";

app-screen-seats-seat {
  div.seat {
    width: 100%;
    height: 100%;
    border: 1px solid #afafaf;
    font-family: 'Font Awesome 5 Free';

    button {
      cursor: pointer;
      display: block;
      width: 100%;
      height: 100%;
      border: none;
      background-color: transparent;
      margin: 0;
      padding: 0;
      &:before {
        width: 100%;
        height: 100%;
        font-size: 16px;
      }
      &:focus, &.active {
        outline: none;
      }
    }

    &.free {
      @extend .icon, .free;
    }
    &.mine {
      @extend .icon, .mine;
    }

    &.blocked {
      background-color: transparent !important;
      border:2px solid #BDBCBB;
      button {
        cursor: default;
      }
    }

    &.wheelchair {
      background-color: #5688ce;
      button:before {
        content: fa-content($fa-var-wheelchair);
        color:#FFFFFF;
        background-color: #5688ce;
        padding-left:3px;
      }

      &.free {
        background-color: #5688ce;
      }
      &.mine {
        background-color: $background-icon-mine!important;
        button:before {
          background-color: $background-icon-mine!important;
        }
      }
      &.occupied {
        background-color: $background-icon-occupied!important;
        button:before {
          background-color: $background-icon-occupied!important;
        }
      }
    }

    &.occupied {
      @extend .icon, .occupied;

      button {
        cursor: default;
      }
    }

    //&.couch {
    //  background-color: #61D998;
    //  border-color: #8e8e8e;
    //}
  }
}


