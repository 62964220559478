app-order-movie-details {
  display: block;
  p {
    margin: 0px 0px 0px 0px;
  }
  hr {
    margin: 0.5rem 0rem;
  }
  .movie-details-container {

    .movie-title {
      font-size: 1.5rem;
      line-height: 1.1;
      margin: 0px 0px 10px 0px;
    }

    .cinema-street:before {
      background-image: url("/assets/images/localization.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: left center;
      color: $black;
      padding: 0 .8rem 0 0;
      width: 1rem;
      height: 1rem;
      content: '';
      margin-right: 5px;
    }
    .cinema-street {
      overflow: hidden;
    }

  }
}

*[dir="rtl"] {

  app-order-movie-details {

    .movie-details-container {

      .cinema-street {

        &:before {

          margin-left: 0.2rem;
        }

      }
    }

  }

}
