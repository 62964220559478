app-screening-list,*[app-screening-list] {
  table {
    margin: 2rem 0rem;
  }

  .strike {

    padding-top: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid #9b9b9b;

    width: 89%;

  }
}
