app-payment-provider-intercard-component, app-payment-provider-basys-component {
  input {
    width: 100%;
    border-radius: 5px;
    border: 1px solid lightgray;
    height: 40px;
  }

  .pay-method-container {
    background-color: $white;

    .pay-method-panel {
      border: solid 2px #f4f4f7;
      margin: 5px 0 5px 0;
      border-radius: 15px;
      background-color: #dadada;
      font-size: .9rem;
      line-height: 1.6;

      .panel-head {
        top: 10px;
        height: 30px;
      }

      .label {
        font-size: 0.9rem;
        font-weight: bold;
        margin: 0;
      }

      .error {
        font-size: 0.9rem;
      }

      .image {
        display: inline-block;
        float: right;

        img {
          height: 30px;
        }
      }
    }

  }
}
