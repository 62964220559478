app-cart-popup-cart-component {

  .dropdown-menu {

    min-height: 0.1vh;
    position: fixed !important;
    bottom: 0;
    background: #FFFFFF;
    border-width: 0 0 0 0;
    z-index: 10000;
    border-radius: 40px 40px 0 0 !important;
    padding-top: 40px;
    left: 50%;
    right: 0;
    width: 95% !important;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    top:unset;



  }

  .item-container {

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;

    }

    &::-webkit-scrollbar-thumb {
      background-color: $violet;
      border-radius: 30px;
    }

    scrollbar-color: $violet transparent;
    scrollbar-width: thin;

    overflow-y: hidden;
    overflow-x: auto;

    max-height: 100px;

  }

}

[dir="rtl"] {

  app-cart-popup-cart-component {

    .dropdown-menu {

      min-height: 0.1vh;
      position: fixed !important;
      bottom: 0;
      background: #FFFFFF;
      border-width: 0 0 0 0;
      z-index: 10000;
      border-radius: 40px 40px 0 0 !important;
      padding-top: 40px;
      left: 50%;
      right: 0;
      width: 95% !important;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      top:unset;

    }


  }



}
