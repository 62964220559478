app-order-tickets-summary {

  .order-tickets-summary-container {

    .order-tickets-summary-block {

      .row {

        .c-price-header {

          font-size: 0.8rem;

        }

      }

    }

  }

}
