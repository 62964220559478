html,
body {
  -webkit-font-smoothing: antialiased;

  color: $white;
  font-size: $font-size-base;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1.46;
  letter-spacing: normal;
}

body {
  background-color: $dark-blue !important;
}

*:focus,
*:active:focus,
*.active:focus,
*.focus,
*:active.focus,
*.active.focus {
  box-shadow: none !important;
  outline: none !important;
}

span {
  font-family: $font-family-base;
}

h3,
h4,
h5,
h6,
h7,
h8,
.h3,
.h4,
.h5,
.h6,
.h7,
.h8 {
  color: $white;
}

h3,
h4,
h5,
h6 {
  letter-spacing: 0.01em;
}
h3,
.h3 {
  font-family: $font-family-base;
  font-size: 22px;
  font-size: 1.6923076923rem;
  font-weight: normal;
  line-height: normal;
  margin: 0 0 1em;
  padding: 0;
}

a {
  color: $default-color;

  :hover {
    color: $default-color;
  }
  :link {
    color: $default-color;
    text-decoration: none;
  }
}

img {
  height: auto !important;
}
img {
  max-width: 100%;
}

img {
  border: 0;
}
img {
  border-style: none;
}

.mb-10 {
  margin-bottom: 5rem !important;
}

.pointer {
  cursor: pointer;
}

.message {
  color: #bb0000;
  font-weight: bold;
  border-top: 0px solid #bb0000;
  border-bottom: 0px solid #bb0000;
  background: #ffd6d6;
  padding: 20px;
  margin: 10px -120px;
  text-align: center;
}

.table {
  color: #4a4a4a;
}

.card {
  border: 6px solid $card-border-color !important;
  border-radius: 0 !important;
  background-color: $dark-blue;
  color: $white;
}

.card-box-shadow {
  -webkit-box-shadow: 1px 4px 7px 0px rgba(156, 154, 156, 1);
  -moz-box-shadow: 1px 4px 7px 0px rgba(156, 154, 156, 1);
  box-shadow: 1px 4px 7px 0px rgba(156, 154, 156, 1);
}

.purple-text {
  color: $dark-blue;
}

.modal {
  &-header {
    background: $dark-blue;
  }
  &-footer {
    background: $white;
  }
}

hr {
  margin: 0.4rem 0 0 0;
  border-width: 2px;
  border-color: $dark-gray;
  &.thin {
    margin: 0;
    border-width: 1px;
  }
}

app-layout {
  &[dir="rtl"] {
    text-align: right;
    //* {
    //  unicode-bidi: plaintext;
    //}

    @each $breakpoint in map-keys($grid-breakpoints) {
      @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
        .text#{$infix}-left {
          text-align: right !important;
        }
        .text#{$infix}-right {
          text-align: left !important;
        }
        .text#{$infix}-center {
          text-align: center !important;
        }
      }
    }

    @each $breakpoint in map-keys($grid-breakpoints) {
      @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .float#{$infix}-left {
          float: right !important;
        }
        .float#{$infix}-right {
          float: left !important;
        }
        .float#{$infix}-none {
          float: none !important;
        }
      }
    }

    @each $breakpoint in map-keys($grid-breakpoints) {
      @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
        @each $size, $length in $spacers {
          .ml#{$infix}-#{$size} {
            margin-left: initial !important;
            margin-right: #{$length} !important;
          }
          .mr#{$infix}-#{$size} {
            margin-right: initial !important;
            margin-left: #{$length} !important;
          }
          .pl#{$infix}-#{$size} {
            padding-left: initial !important;
            padding-right: #{$length} !important;
          }
          .pr#{$infix}-#{$size} {
            padding-right: unset !important;
            padding-left: #{$length} !important;
          }
        }
      }
    }

    @each $size, $length in $spacers {
      .ml-#{$size} {
        margin-left: initial !important;
        margin-right: #{$length} !important;
      }

      .mr-#{$size} {
        margin-right: initial !important;
        margin-left: #{$length} !important;
      }

      .pl-#{$size} {
        padding-left: initial !important;
        padding-right: #{$length} !important;
      }

      .pr-#{$size} {
        padding-right: initial !important;
        padding-left: #{$length} !important;
      }
    }

    @include make-grid-columns-rtl();
  }
}

@media (max-width: 767px) {
  body {
    font-size: 0.8rem;
    min-width: 356px;
    overflow-x: hidden;
  }
}

@include make-special-grid-columns();

a {
  color: $light-blue;
  text-decoration: none;

  &.nav-link {
    color: white;
  }

  &:hover {
    color: $light-blue;
  }
}

.color-orange {
  color: $orange !important;

  .btn-link {
    @extend .color-orange;
    font-size: 1.1rem;
  }
}

.color-blue .btn-link {
  color: $light-blue !important;
}

.rotate {
  &-45 {
    transform: rotate(45deg);
  }
}

.line-height {
  &-1 {
    line-height: 1rem;
  }
}

.logout-icon {
  background: url("/assets/images/power-icon.svg");
  background-repeat: no-repeat;
  min-height: 25px;
  min-width: 25px;
  background-size: contain;
  filter: invert(100%) sepia(93%) saturate(0%) hue-rotate(0deg) brightness(119%) contrast(119%);
}

.rgap {
  &-03 {
    row-gap: 0.3rem;
  }
  &-05 {
    row-gap: 0.5rem;
  }
  &-1 {
    row-gap: 1rem;
  }
}

.gap {
  &-03 {
    gap: 0.3rem;
  }
  &-05 {
    gap: 0.5rem;
  }
  &-1 {
    gap: 1rem;
  }
  &-2 {
    gap: 2rem;
  }
}

.fs-1 {
  font-size: 1rem;
}

.error {
  color: $error-color;
}
