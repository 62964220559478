app-order-order-summary {
  .selected-seats {
    color: $light-gray;
    font-size: 0.9rem;
  }

  .order-tickets-summary-block {
    width: 100%;
    padding-top: 11px;

    .b1 {
      font-size: 1rem;
    }

    .increment {
      position: relative;
      bottom: 1px;
      padding: 0.1rem 0.2rem;
      img {
        width: 22px;
      }
    }

    .fb-item-description {
      font-size: 0.85rem;
    }
  }

  .to-pay {
    font-size: 1.4rem;
  }

  .block {
    border-bottom: none;
    padding: 0px 0px;
    margin: 0px 0px;

    &:first-of-type {
      padding: 0px;
      margin: 0px;
    }

    &:last-of-type {
      border-bottom: none;
    }

    &.block-fee {
      background: #ffffff;
      border-radius: 2rem;
      display: block;
      border: 0 none;
    }
  }

  h4 {
    padding-bottom: 0.5rem;
  }

  .fb-item-description {
    font-size: 0.6rem;
    color: $gray-600;
  }

  .fb-summary-cell {
    padding: 0 !important;
    padding-left: 0.75rem !important;
  }

  .table {
    tr {
      td {
        border: 0 none;
        width: 33%;
        vertical-align: middle;

        span.help-block {
          display: block;
          color: $gray;
        }
      }
    }
  }

  .info {
    color: $gray;
    padding: 0px 15px;
  }

  .total-price-info {
    font-size: 0.7rem;
    display: block;
    color: gray;
  }

  .discount {
    color: $light-blue;
  }
}

*[dir="rtl"] {
  app-order-order-summary {
    .fb-summary-cell {
      padding-left: initial !important;
      padding-right: 0.6rem !important;
    }
  }
}
