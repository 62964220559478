app-catering-article {

  .article {

    width: 9rem;
    display: flex;
    flex-direction: column;

    .default-image {
      background-image: url("/assets/images/logo_header.png");
      background-color: #720d1e;
      background-size: 7rem !important;
    }

    .image {

      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      width: 9rem;
      height: 9rem;
      box-shadow: inset 0px 39px 44px -7px rgba(0,0,0,0.65);

      .name {

        text-transform: uppercase;
        font-weight: bold;
        color: $white;
        line-height: 1;

      }


    }

    .description {

      font-size: .9rem;
      line-height: 1;

    }

    .price-box {

      margin-top: .4rem;

      .price {
        font-weight: bold;

      }

      .add {

        .add-button {

          float: right;
          display: flex;
          width: 2.8rem;
          height: 1.5rem;
          background-color: gray;
          text-transform: uppercase;
          justify-content: center;
          align-content: center;
          align-items: center;
          user-select: none;

          .label {

            color: white;
            font-size: .6rem;

          }

          &:hover{

            background-color: #720d1e;
            cursor: pointer;

          }

        }


      }


    }

  }

}
