app-user-register {
  .register-button {
    color: #004b87;
    text-decoration: underline;
    font-weight: bold;
  }

  .card-footer {
    padding: 0;
  }

  .card-body {
    padding: 0px;
  }

  .card-header {
    padding: 20px 0px;
  }

  h3 {
    font-weight: bold;
  }
}

*[dir="rtl"] {
  app-user-register {
    .agreements {
      text-align: right;
    }
  }
}
