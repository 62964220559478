app-choice-slider {
  .items {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    min-height: 96px;
    width: 100%;
    text-align: center;
    justify-content: center;
  }

  .item {
    width: 96px;
    height: 96px;
    margin: 0 8px;
    object-fit: cover;
    border-radius: 12px;
    padding: 6px;

    img {
      object-fit: cover;
      aspect-ratio: 1 / 1;
    }
  }

  .item.selected {
    border: 6px solid $light-blue;
    padding: 0;
    border-radius: 6px;
  }

  span.fa {
    font-size: 2rem;
  }
}
