app-user-history-order-component {

  .user-history {

    padding-top: 30px;
    padding-bottom: 30px;

    .card {

      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      min-width: 0;
      word-wrap: break-word;
      background-color: #fff;
      background-clip: border-box;
      border: 0 solid rgba(0,0,0,.125);
      border-radius: 2.5rem;

    }

    .card-body {

      margin-left: 50px;
      margin-right: 50px;
      padding-top: 30px;
      padding-bottom: 30px;
      margin-bottom: 50px;
    }

    .card-indentation {

      width: 100px;
      height: 100px;
      border-radius: 50%;
      position: absolute;
      top: calc(50% - 50px);
      background-color: #edeeef;


    }

    .card-indentation-left {

      left: -50px;

    }

    .card-indentation-right {

      right: -50px;
    }

    .card-indentation-top {

      top: calc(0% - 50px);
      left: calc(50% - 50px);

    }

    .card-indentation-bottom {

      top: calc(100% - 75px);
      left: calc(50% - 50px);

    }

    .card-top-indentation-circle {

      width: 100px;
      height: 100px;
      position: absolute;
      left: calc(50% - 100px);
      background-color: #edeeef;
      top: calc(0% - 50px);

    }

    .card-top-indentation-left {

      width: 50px;
      height: 50px;
      position: absolute;
      left: calc(50% - 100px);
      background-color: white;
      top: 0;
      border-top-right-radius: 150%;

    }

    .card-top-indentation-right {

      width: 50px;
      height: 50px;
      position: absolute;
      left: calc(50% - 50px);
      background-color: white;
      top: 0;
      border-top-left-radius: 150%;

    }

    .card-bottom-indentation-circle {

      width: 100px;
      height: 100px;
      position: absolute;
      left: calc(50% - 100px);
      background-color: #edeeef;
      top: calc(100% - 50px);

    }

    .card-bottom-indentation-left {

      width: 50px;
      height: 50px;
      position: absolute;
      left: calc(50% - 100px);
      background-color: white;
      top: calc(100% - 50px);
      border-bottom-right-radius: 150%;

    }

    .card-bottom-indentation-right {

      width: 50px;
      height: 50px;
      position: absolute;
      left: calc(50% - 50px);
      background-color: white;
      top: calc(100% - 50px);
      border-bottom-left-radius: 150%;

    }

    .show-toggle {

      color: $blue;

    }

    .show-toggle:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    .qr-block {
      border-width: 0 0 0 1px;
      border-style: solid;
      border-color: #d8d8d8;
      padding-left: 20px;
    }

  }


}

*[dir="rtl"] {

  app-user-history-order-component {

    .user-history {

      .qr-block {

        border-width: 0 1px 0 0 !important;;
      }

    }
  }

}
