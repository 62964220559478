app-cart-cart-area-component {

  .cart-button {

    .cart-icon {

      width: 15px;
      height: 15px;
      display: inline-block;
      background-image: url("/assets/images/cart_white.svg");
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: contain;

    }

  }

}
