app-personal {
  .personal-card {
    border-radius: 16px !important;
    background-color: #dadada;

    .card-header {
    }

    .card-body {
      font-size: 0.9rem;
      line-height: 1.6;

      .header-info {
        font-weight: bold;
      }
    }

    .card-footer {
    }
  }

  input[disabled] {
    border: 1px solid #3b3e42;
    background-color: #282828 !important;
    color: $white;
  }
}
