app-catering-article-group-container {
  .view-container {
    .group-tab-container {
      justify-content: center;
    }

    .group-tab-container {
      display: block;
      width: unset;
      white-space: nowrap;
      overflow: auto !important;

      .tab-block {
        min-width: 6rem;
        width: unset;
      }
    }
  }
}
