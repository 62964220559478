app-page-login {
  .card-header {
    padding: 0;

    img {
      width: 100%;
    }

    h3 {
      font-size: 1.5rem !important;
    }
  }

  .card-body {
    padding: 0px 120px;
  }

  .message {
    color: #bb0000;
    font-weight: bold;
    border-top: 0px solid #bb0000;
    border-bottom: 0px solid #bb0000;
    background: #ffd6d6;
    padding: 20px;
    margin: 10px -120px;
    text-align: center;
  }

  .login-button {
    width: 100%;
  }
}
