app-page-order-summary {
  display: flex;
  flex-flow: column;
  height: 100%;
  flex: 1 0 auto;

  .order-summary-container {
    display: flex;
    flex-flow: column;
    height: 100%;
    flex: 1 0 auto;

    .container {
      flex: 1 0 auto;
      border-bottom: 6px solid $dark-blue;
    }
  }

  h7,
  .h7 {
    font-size: 1.2rem;
  }

  .hrline-block {
    border: none !important;
  }

  .fb-item-description {
    font-size: 1.1rem;
  }

  app-order-order-summary {
    .fb-summary-cell {
      padding: 0 !important;
    }

    .summary-item {
      td {
        padding-top: 0.6rem !important;
      }
    }
  }

  .screen-details {
    margin-top: 125px;

    p {
      margin: 0;
    }
  }

  .b1 {
    font-size: 1.2rem;
  }

  .c-title {
    text-transform: uppercase;
  }

  .hrline-block {
    height: 1px;
    border-bottom: 1px solid #666666;
  }

  table {
    width: 100%;
  }

  .fb-summary-cell-p {
    padding: 0 !important;
  }

  app-order-movie-details .movie-details-container {
    .movie-title {
      font-weight: bold;
    }

    .cinema-street {
      font-weight: bold;
      padding: 5px 0;
    }
  }

  .payment-failed {
    padding-top: 2.2rem;
  }

  .transaction {
    font-size: 18px;
    margin-bottom: 10px !important;

    &-successful {
      @extend .transaction;
      color: $yellow;
    }

    &-failed {
      @extend .transaction;
      color: red;
    }

    &-pending {
      @extend .transaction;
      color: orange;
    }
  }

  .transaction-details {
    font-size: 14px;
  }

  .failed {
    h3 {
      color: #bb0000;
      font-size: 16px;
      font-weight: bold;
      margin: 0;
    }
  }

  .order-summary-container {
    .order-summary-qr-container {
      border-width: 0 0 0 1px;
      border-style: solid;
      border-color: #d8d8d8;
      padding-left: 20px;
    }

    .head-image-wrapper {
      height: 270px;
      width: 100%;
      background-position: center;
      background-size: contain;
    }

    .zoom {
      cursor: pointer;
      color: black;
      font-weight: bold;

      &:before {
        content: "";
        width: 22px;
        height: 22px;
        display: block;
        background: url("/assets/images/zoom_code.svg");
        text-align: center;
        margin: auto;
        border: 2px solid #9b9b9b;
        border-radius: 50%;
        padding: 15px;
        background-position: center;
        background-repeat: no-repeat;
        margin-top: 8px;
        margin-bottom: 8px;
        cursor: pointer;
      }
    }

    .head-wrapper {
      padding: 2rem 0px 2rem 75px;
      h3 {
        color: #000000;
      }

      p.subtitle {
        color: $gray;
      }
    }

    .booking-wrapper {
      .card {
        border-radius: 2.5rem;
        position: relative;

        .card-indentation {
          width: 150px;
          height: 250px;
          position: absolute;
          top: calc(50% - 50px);
          background-repeat: no-repeat;
          background-size: 100px;
        }

        .card-indentation-pos {
          left: -30px;
          top: 60px;
        }

        .card-indentation-left {
          left: -50px;
        }

        .card-indentation-top {
          left: calc(50% - 50px);
          top: -50px !important;
        }

        .card-indentation-bottom {
          left: calc(50% - 50px);
          bottom: -50px !important;
          top: unset;
        }

        .card-indentation-right {
          right: -50px;
        }

        &.card-movie {
          .card-body {
            padding-top: 4rem;
            padding-left: 4rem;
            padding-right: 4rem;
          }
        }

        &.card-order {
          .card-body {
            padding-top: 4rem;
            padding-right: 4rem;
            padding-left: 4rem;
          }

          th,
          td {
            padding: 0px;
          }
        }
      }

      .lightbox img {
        margin: 12px auto;
      }
    }

    .foot-wrapper {
      margin: 3rem 0;
      background: $gray-600;
      padding: 0.5rem 3rem;
      color: $white;
      border-radius: 2rem;
    }
  }
}

a.lightbox img {
  cursor: pointer;
}

/* Styles the lightbox, removes it from sight and adds the fade-in transition */

.lightbox-target {
  position: fixed;
  top: -100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  opacity: 0;
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -o-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
  overflow: hidden;
  z-index: 10;
}

/* Styles the lightbox image, centers it vertically and horizontally, adds the zoom-in transition and makes it responsive using a combination of margin and absolute positioning */

.lightbox-target img {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 0%;
  max-width: 0%;
  border: 3px solid white;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

/* Styles the close link, adds the slide down transition */

span.lightbox-close {
  display: block;
  width: 50px;
  height: 50px;
  box-sizing: border-box;
  background: transparent;
  color: #05c3de;
  text-decoration: none;
  position: absolute;
  top: -80px;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

/* Provides part of the "X" to eliminate an image from the close link */

span.lightbox-close:before {
  content: "";
  display: block;
  height: 30px;
  width: 2px;
  background: #05c3de;
  position: absolute;
  left: 26px;
  top: 10px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Provides part of the "X" to eliminate an image from the close link */

span.lightbox-close:after {
  content: "";
  display: block;
  height: 30px;
  width: 2px;
  background: #05c3de;
  position: absolute;
  left: 26px;
  top: 10px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

/* Uses the :target pseudo-class to perform the animations upon clicking the .lightbox-target anchor */

.lightbox-target.active {
  opacity: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.lightbox-target.active img {
  max-height: 100%;
  max-width: 90%;
}

.lightbox-target.active span.lightbox-close {
  top: 0px;
}

*[dir="rtl"] {
  app-page-order-summary {
    .card-indentation-left {
      right: -50px !important;
      left: unset !important;
    }
    .card-indentation-right {
      left: -50px !important;
      right: unset !important;
    }

    .order-summary-qr-container {
      border-width: 0 1px 0 0;
      border-style: solid;
      border-color: #d8d8d8;
      padding-right: 20px;
    }
  }

  span.lightbox-close {
    left: 0;
  }
}

*[dir="ltr"] {
  span.lightbox-close {
    right: 0;
  }
}

*[dir="rtl"] {
  app-page-order-summary {
    app-order-order-summary {
      .fb-summary-cell {
        padding-left: initial !important;
        padding-right: 0 !important;
      }
    }
  }
}
