app-page-register {

  .card-body {
    padding: 0px 10px;
  }

  .next-info {
    background-color: #dff7fb;
    margin: -52px -30px;
    padding: 20px 32px;

    span {
      font-weight: bold;
      color: #004b87;
      text-decoration: underline;

    }
  }

  .message {
    margin:10px -10px;
  }
}
