@keyframes counter-animation {

  0% {
    background: #4b4b4a;
  }

  50% {
    background: #8b8b8b;
  }

  100% {
    background: #4b4b4a;
  }
}
@-moz-keyframes counter-animation {

  0% {
    background: #4b4b4a;
  }

  50% {
    background: #8b8b8b;
  }

  100% {
    background: #4b4b4a;
  }
}

@-webkit-keyframes counter-animation {

  0% {
    background: #4b4b4a;
  }

  50% {
    background: #8b8b8b;
  }

  100% {
    background: #4b4b4a;
  }
}


app-countdown {
  span.clock-countdown {
    display: inline-block;
    border: 2px solid #FFFFFF;
    color: #FFFFFF;
    font-size: 14px;
    height: 58px;
    width: 58px;
    padding: 0px;
    text-align: center;
    //line-height: 58px;
  }
}