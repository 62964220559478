app-screen-navigation {
  .navigation-container, .screen-navigation-container {
    width: 100%;

    button {
      min-height: 39px;
      position: relative;

      app-loading {
        position: absolute;
        opacity: 0.5;
        .backdrop {
          display: none;
        }
        .spinner-circle-swish {
          font-size: 16px!important;
        }
      }
    }

    app-loading {
      display: block;
    }
  }
}
